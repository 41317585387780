import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ExportButton from '../../Buttons/ExportButton';
import CustomTooltip from '../../aegeon/CustomTooltip';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';
import MonotoneCardDetails from './MonotoneCardDetails';

const MonotonePageDetails = (props) => {
  const t = useTranslate();
  const title = t("devices.monotone.title");
  const globalClasses = globalUseStyles();
  const [data, setData] = useState(null);

  const setDataCallback = (childData) => {
    if (childData) {
      setData(childData)
    }
  }

  const [subtitle, setSubtitle] = useState(" ");

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitle(childData);
    }
  };

  const handleExport = (name) => {
    var sheets_data = [];
    if (data) {
      sheets_data.push({ sheetName: "Monotone", data: data });
      export_row_to_excel(sheets_data, `${t('devices.exports.monotone')}${name}.xlsx`);
    };
  };

  return (
    <DetailsShow title={title} resource="devices" tooltip={<ExportButton onClick={handleExport} disabled={!data} />}>
      <Card className={globalClasses.DetailCard}>
        <div className={globalClasses.DetailCardTableTitle}>
          <Typography variant="h8">{t('devices.monotone.detailledTitle')}</Typography>
          {<CustomTooltip title={t('devices.monotone.tooltip_text')} icon={<InfoTooltipIcon />} />}
        </div>
        <div className={globalClasses.DetailCardTableSubtitle}>
          {subtitle}
        </div>
        <MonotoneCardDetails setSubtitleCallback={handleSetSubtitleCallback} setDataCallback={setDataCallback} />
      </Card>
    </DetailsShow>
  );
};

export default MonotonePageDetails;

