import React, { useEffect, useState } from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ChartLine from '../../aegeon/charts/ChartLine';
import QueryField from '../../aegeon/query/QueryField';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";

const moment = require('moment');

const startDateOneYear = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(15, 'days');
const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
const filter = { startDate: startDateOneYear, endDate: endDate };

const LineCardDetails = (props) => {
  const globalClasses = globalUseStyles();

  const [data, setData] = useState(null);
  const setDataCallback = (childData) => {
    if (childData && !data) {
      setData(childData);
    }
  };

  useEffect(() => {
    props.setDataCallback(data);
  }, [data, props]);

  return (
    <TimeseriesField source={"active_power"} filter={filter}>
      <div style={{ width: "100%", height: "550px" }} >
        <QueryField query_name='getDeviceContract' >
          <ChartLine
            isWeekendsHighlight={true}
            isNightsHighlight={true}
            isDetailedCard={true}
            setLineDataCallback={setDataCallback}
            isZoom={true}
          />
        </QueryField>
      </div>
    </TimeseriesField>
  )
};

export default LineCardDetails;

