const translateUsersFr = {
  users: {
    title: "Gestion des utilisateurs",
    page_subtitle: "",
    sort_button: {
      sort_by_default: "RÉINITIALISER LE TRI",
      sort_by_default_tooltip: "Réinitialiser le tri par défaut",
    },
    synchronize_button: {
      name: "SYNCHRONISER",
      tooltip: "Synchroniser",
    },
    synchronize: {
      title: "Synchronisation des utilisateurs",
      page_subtitle: "",
      error_no_data: "Aucune donnée à synchroniser",
      error: "Erreur lors de la synchronisation, merci de vérifier les données",
      success: "Synchronisation réussie",
      old_value_tooltip: "Ancienne valeur : ",
      new_value: "Ajouté",
      old_value: "Supprimé",
      periods: "Périodes d'accès : ",
      start_date: " Début : ",
      end_date: " Fin : ",
      no_date: "Non défini",
    },
    create:{
      title: "Ajouter un utilisateur",
    },
    configuration: {
      title: "Configuration",
      subtitle: "",
      tooltip_text: "",
      source: "Source",
      user: "Utilisateur Airtable (email)",
      key: "Clé",
      synchronize_all: "Synchroniser tous les tenants",
      upload_file: "Télécharger le fichier",
      download_exemple_file: "Télécharger le fichier pivot",
      dropzone_text: "Déposez le fichier ici",
    },
    preview:{
      title: "Prévisualisation de la synchronisation",
      subtitle: "",
      tooltip_text: "",
    },
    general_information: {
      title: "Informations générales",
      subtitle: "",
      tooltip_text: "",
      name: "Utilisateur",
      email: "Email",
      profile: "Profil",
      status: "Statut",
      enabled_user: "Utilisateur actif",
      enabled: "Activé",
      disabled: "Désactivé",
      profiles: {
        admin: "Administrateur",
        property_owner: "Propriétaire",
        property_manager: "Property Manager",
        property_tenant: "Locataire",
        undefined: "Non défini",
        null: "Non défini",
      },
      username_invalid: "Nom d'utilisateur invalide : veuillez utiliser le format prenom.nom",
    },
    perimeter: {
      title: "Périmètre",
      subtitle: "Le périmètre du profil est défini par les compteurs et les bâtiments auxquels l'utilisateur a accès. Les administrateurs ont accès à l'ensemble du périmètre.",
      tooltip_text: "Le périmètre du profil est défini par les compteurs et les bâtiments auxquels l'utilisateur a accès.",
      sites: "Bâtiments",
      buildings: "Bâtiments",
      devices: "Compteurs",
      devicesWithTimeConstraints: "Compteurs avec limites de temps",
      devicesWithTimeConstraintsSubtitle: "Si le locataire a des restrictions de temps, ajoutez ici les compteurs avec leurs périodes associées. Si aucune période n'est définie, l'accès est permanent. L'onglet précédent doit être vide pour prendre en compte ce périmètre-là.",
      device: "Compteur",
      addDevice: "Ajouter un compteur",
      periods: "Périodes d'accès au compteur",
      addPeriod: "Ajouter une période",
      endDateToday: "Aujourd'hui",
      startDate: "Date de début",
      endDate: "Date de fin (optionnelle)",
    },
    button: {
      cancel: "ANNULER",
      synchronize: "CONTINUER",
      save: "ENREGISTRER",
      apply: "APPLIQUER",
    },
    notifications:{
      updated_error: "Erreur lors de la mise à jour de l'utilisateur",
      updated_success: "Utilisateur mis à jour avec succès",
      created_error: "Erreur lors de la création de l'utilisateur",
      created_success: "Utilisateur créé avec succès. Voici votre mot de passe, à copier et à conserver précieusement :",
      password_title: "Attention, veuillez copier et conserver précieusement le mot de passe généré ci-dessous. Il ne sera pas possible de le récupérer ultérieurement.",
      password_message: "Mot de passe : ",
      password_copy_button: "COPIER LE MOT DE PASSE ET QUITTER",
      time_constraints_warning: "Attention, les deux types de compteurs sont renseignés donc seulement le premier sera pris en compte",
    }
  },
};

export default translateUsersFr;
