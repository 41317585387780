/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBuilding = /* GraphQL */ `
  query GetBuilding($id: ID!) {
    getBuilding(id: $id) {
      Devices {
        items {
          airtable {
            analog_periods_without_data
            authorization_end_date
            automatic_upload
            building_tenths {
              building_tenth
              distribution_tenth
              distribution_tenth_comment
              organisation_tenant
              property_owner_tenth
              site_code
              site_id
              tenth
              __typename
            }
            consumption_2010_10years_in_kwh_pcs
            consumption_2011_10years_in_kwh_pcs
            consumption_2012_10years_in_kwh_pcs
            consumption_2013_10years_in_kwh_pcs
            consumption_2014_10years_in_kwh_pcs
            consumption_2015_10years_in_kwh_pcs
            consumption_2016_10years_in_kwh_pcs
            consumption_2017_10years_in_kwh_pcs
            consumption_2018_10years_in_kwh_pcs
            consumption_2019_10years_in_kwh_pcs
            consumption_2020_10years_in_kwh_pcs
            consumption_2021_10years_in_kwh_pcs
            consumption_2022_10years_in_kwh_pcs
            contextual_information
            contract_holder
            current_synoptic_quality_reporting
            customer_space_available
            deet_category
            delegated_authorization
            deposited
            destination
            distributor_name
            energy_data_upload_possibilities
            fluid_type
            functional_entities {
              code
              lots
              surface
              tenant_code
              tenant_name
              type
              __typename
            }
            last_analog_invoice_date
            named_authorization
            pdl_id
            placement
            quality_of_data_completeness_2023
            questions_about_pdl_to_energy_manager
            site_code
            site_name
            sites {
              address
              city
              code
              current_synoptic_quality_reporting
              id
              name
              organisation_tenant
              postal_code
              __typename
            }
            sub_metering
            supplier_name
            tenth
            unique_point
            usage
            __typename
          }
          analysis {
            meter_commissioning {
              created_at
              text
              __typename
            }
            __typename
          }
          buildingId
          deviceType
          fusionedEnergyQuality
          id
          name
          properties {
            id
            name
            quality {
              completenes_end_date
              completenes_start_date
              completeness
              completeness_end_date
              completeness_start_date
              ignored_intervals {
                end_date
                start_date
                __typename
              }
              missing_values
              outliers {
                timestamp
                unit
                value
                __typename
              }
              overall_quality
              overall_quality_reason
              timeseries_end_date
              timeseries_start_date
              __typename
            }
            unit
            value
            __typename
          }
          siteCode
          siteCodes
          siteId
          siteIds
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      id
      location {
        address
        city
        country
        latitude
        longitude
        zipcode
        __typename
      }
      name
      portfolioId
      __typename
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      airtable {
        analog_periods_without_data
        authorization_end_date
        automatic_upload
        building_tenths {
          building_tenth
          distribution_tenth
          distribution_tenth_comment
          organisation_tenant
          property_owner_tenth
          site_code
          site_id
          tenth
          __typename
        }
        consumption_2010_10years_in_kwh_pcs
        consumption_2011_10years_in_kwh_pcs
        consumption_2012_10years_in_kwh_pcs
        consumption_2013_10years_in_kwh_pcs
        consumption_2014_10years_in_kwh_pcs
        consumption_2015_10years_in_kwh_pcs
        consumption_2016_10years_in_kwh_pcs
        consumption_2017_10years_in_kwh_pcs
        consumption_2018_10years_in_kwh_pcs
        consumption_2019_10years_in_kwh_pcs
        consumption_2020_10years_in_kwh_pcs
        consumption_2021_10years_in_kwh_pcs
        consumption_2022_10years_in_kwh_pcs
        contextual_information
        contract_holder
        current_synoptic_quality_reporting
        customer_space_available
        deet_category
        delegated_authorization
        deposited
        destination
        distributor_name
        energy_data_upload_possibilities
        fluid_type
        functional_entities {
          code
          lots
          surface
          tenant_code
          tenant_name
          type
          __typename
        }
        last_analog_invoice_date
        named_authorization
        pdl_id
        placement
        quality_of_data_completeness_2023
        questions_about_pdl_to_energy_manager
        site_code
        site_name
        sites {
          address
          city
          code
          current_synoptic_quality_reporting
          id
          name
          organisation_tenant
          postal_code
          __typename
        }
        sub_metering
        supplier_name
        tenth
        unique_point
        usage
        __typename
      }
      analysis {
        meter_commissioning {
          created_at
          text
          __typename
        }
        __typename
      }
      buildingId
      deviceType
      fusionedEnergyQuality
      id
      name
      properties {
        id
        name
        quality {
          completenes_end_date
          completenes_start_date
          completeness
          completeness_end_date
          completeness_start_date
          ignored_intervals {
            end_date
            start_date
            __typename
          }
          missing_values
          outliers {
            timestamp
            unit
            value
            __typename
          }
          overall_quality
          overall_quality_reason
          timeseries_end_date
          timeseries_start_date
          __typename
        }
        unit
        value
        __typename
      }
      siteCode
      siteCodes
      siteId
      siteIds
      __typename
    }
  }
`;
export const getDeviceContract = /* GraphQL */ `
  query GetDeviceContract($id: ID!) {
    getDeviceContract(id: $id) {
      contracts {
        car
        cja
        date_mes
        profil {
          profil_type_actuel
          __typename
        }
        tarif_acheminement
        __typename
      }
      donneesGenerales {
        adresseInstallation {
          batiment
          codePostal
          commune {
            code
            libelle
            __typename
          }
          escalierEtEtageEtAppartement
          numeroEtNomVoie
          __typename
        }
        dateDerniereModificationFormuleTarifaireAcheminement
        etatContractuel {
          code
          libelle
          __typename
        }
        niveauOuvertureServices
        segment {
          code
          libelle
          __typename
        }
        __typename
      }
      pdl_id
      permissions {
        code_postal
        courrier_titulaire
        date_creation
        date_debut_droit_acces
        date_fin_droit_acces
        date_limite_transmission_preuve
        date_passage_a_obsolete
        date_passage_a_refuse
        date_revocation
        etat_droit_acces
        id_droit_acces
        id_pce
        nom_titulaire
        numero_telephone_mobile_titulaire
        parcours
        perim_donnees_conso_debut
        perim_donnees_conso_fin
        perim_donnees_contractuelles
        perim_donnees_informatives
        perim_donnees_publiees
        perim_donnees_techniques
        raison_sociale_du_tiers
        raison_sociale_du_titulaire
        role_tiers
        source_passage_a_obsolete
        source_passage_a_refuse
        source_revocation
        statut_controle_preuve
        __typename
      }
      situationAlimentation {
        alimentationPrincipale {
          domaineTension {
            code
            libelle
            __typename
          }
          puissanceRaccordementSoutirage {
            unite
            valeur
            __typename
          }
          tensionLivraison {
            code
            libelle
            __typename
          }
          __typename
        }
        __typename
      }
      situationComptage {
        caracteristiquesReleve {
          modeTraitement {
            code
            libelle
            __typename
          }
          periodicite {
            code
            libelle
            __typename
          }
          plageReleve {
            code
            libelle
            __typename
          }
          __typename
        }
        dispositifComptage {
          compteurs {
            compteur {
              localisation {
                code
                libelle
                __typename
              }
              matricule
              parametresTeleAcces {
                cle
                etatLigneTelephonique
                __typename
              }
              programmationHoraire {
                programmationPosteHoraire {
                  code
                  libelle
                  periodesHoraires
                  __typename
                }
                __typename
              }
              ticActivable
              ticActivee
              ticStandard
              __typename
            }
            typeComptage {
              code
              libelle
              __typename
            }
            __typename
          }
          disjoncteur {
            calibre {
              code
              libelle
              __typename
            }
            __typename
          }
          relais {
            plageHeuresCreuses
            __typename
          }
          typeComptage {
            code
            libelle
            __typename
          }
          __typename
        }
        futuresPlagesHeuresCreuses {
          code
          libelle
          __typename
        }
        futuresProgrammationsHoraires {
          formuleTarifaireAcheminement {
            code
            libelle
            programmationHoraire {
              programmationPosteHoraire {
                code
                libelle
                periodesHoraires
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        mediaReleve {
          code
          libelle
          __typename
        }
        modeReleve {
          code
          libelle
          __typename
        }
        transformateurCourant {
          calibre {
            code
            libelle
            __typename
          }
          classePrecision {
            code
            libelle
            __typename
          }
          couplage {
            code
            libelle
            __typename
          }
          position {
            code
            libelle
            __typename
          }
          __typename
        }
        __typename
      }
      situationContractuelle {
        structureTarifaire {
          calendrierFrn {
            code
            libelle
            __typename
          }
          denivelePuissances {
            classesTemporelles {
              classeTemporelle {
                code
                libelle
                puissance {
                  unite
                  valeur
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          formuleTarifaireAcheminement {
            code
            libelle
            __typename
          }
          puissanceSouscriteMax {
            unite
            valeur
            __typename
          }
          __typename
        }
        __typename
      }
      technical {
        caracteristiques_compteur {
          client_sensible_mig
          frequence
          __typename
        }
        pitd {
          identifiant_pitd
          libelle_pitd
          __typename
        }
        situation_compteur {
          code_postal
          commune
          complement_adresse
          nom_rue
          numero_rue
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDevicePropertyTimeseries = /* GraphQL */ `
  query GetDevicePropertyTimeseries(
    $aggregation: AggregationTimeseries
    $deviceId: ID!
    $propertyId: ID!
    $timestamp: AWSDateTimeInput
  ) {
    getDevicePropertyTimeseries(
      aggregation: $aggregation
      deviceId: $deviceId
      propertyId: $propertyId
      timestamp: $timestamp
    ) {
      deviceId
      id
      nextToken
      pagination {
        nextToken
        startedAt
        __typename
      }
      propertyId
      timeseries {
        grid_name
        monotone_cumulative_time_in_min
        monotone_end_date
        monotone_execution_time
        monotone_start_date
        nature
        reason
        sampling_rate_in_min
        source
        time
        value
        value_nature_absence
        value_nature_actual
        value_nature_brut
        value_nature_corrected
        value_nature_corrige
        value_nature_coupure_secteur
        value_nature_coupure_secteur_courte
        value_nature_debut_de_coupure_secteur
        value_nature_esti
        value_nature_estimated
        value_nature_estime
        value_nature_fin_de_coupure_secteur
        value_nature_importe_manuellement_par_le_metier_enedis
        value_nature_no_data
        value_nature_puissance_reconstituee_et_coupure_secteur
        value_nature_reel
        value_nature_regu
        value_source_airtable_manual_bills
        value_source_airtable_third_party_active_power_in_kwh
        value_source_airtable_third_party_energy
        value_source_enedis_active_power_daily_in_kwh
        value_source_enedis_billed_energy
        __typename
      }
      unit
      __typename
    }
  }
`;
export const getDevicePropertyTimeseriesAsync = /* GraphQL */ `
  query GetDevicePropertyTimeseriesAsync(
    $aggregation: AggregationTimeseries
    $deviceId: ID!
    $propertyId: ID!
    $timestamp: AWSDateTimeInput
  ) {
    getDevicePropertyTimeseriesAsync(
      aggregation: $aggregation
      deviceId: $deviceId
      propertyId: $propertyId
      timestamp: $timestamp
    ) {
      status
      token
      __typename
    }
  }
`;
export const getDeviceQualityReasoning = /* GraphQL */ `
  query GetDeviceQualityReasoning(
    $id: ID!
    $reasoningId: ID
    $reasoningType: String
  ) {
    getDeviceQualityReasoning(
      id: $id
      reasoningId: $reasoningId
      reasoningType: $reasoningType
    ) {
      items {
        createAt
        endDate
        id
        outdated
        reasoningId
        reasoningText
        reasoningType
        reviewed
        startDate
        updateBy
        updatedAt
        value
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeviceSubdevicesTimeseries = /* GraphQL */ `
  query GetDeviceSubdevicesTimeseries(
    $id: ID!
    $nextToken: String
    $time: AWSDateTimeInput
  ) {
    getDeviceSubdevicesTimeseries(id: $id, nextToken: $nextToken, time: $time) {
      items {
        deviceId
        id
        nextToken
        pagination {
          nextToken
          startedAt
          __typename
        }
        propertyId
        timeseries {
          grid_name
          monotone_cumulative_time_in_min
          monotone_end_date
          monotone_execution_time
          monotone_start_date
          nature
          reason
          sampling_rate_in_min
          source
          time
          value
          value_nature_absence
          value_nature_actual
          value_nature_brut
          value_nature_corrected
          value_nature_corrige
          value_nature_coupure_secteur
          value_nature_coupure_secteur_courte
          value_nature_debut_de_coupure_secteur
          value_nature_esti
          value_nature_estimated
          value_nature_estime
          value_nature_fin_de_coupure_secteur
          value_nature_importe_manuellement_par_le_metier_enedis
          value_nature_no_data
          value_nature_puissance_reconstituee_et_coupure_secteur
          value_nature_reel
          value_nature_regu
          value_source_airtable_manual_bills
          value_source_airtable_third_party_active_power_in_kwh
          value_source_airtable_third_party_energy
          value_source_enedis_active_power_daily_in_kwh
          value_source_enedis_billed_energy
          __typename
        }
        unit
        __typename
      }
      nextToken
      scannedCount
      startedAt
      __typename
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      airtable {
        acquisition_date
        address
        aegeon_energy_data_type
        altitude
        asl_aful_names
        asl_and_aful {
          name
          type
          __typename
        }
        asset_manager_contact {
          email
          name
          organizations_names
          phone_number
          role
          __typename
        }
        billing_contact {
          email
          name
          organizations_names
          phone_number
          role
          __typename
        }
        biodiversity_label
        building_site_plan
        city
        closing_hours
        co_ownership {
          name
          type
          __typename
        }
        co_ownership_name
        code
        commissioning_and_reporting_note_2023
        construction_certification
        construction_date
        consumption_or_production
        counted_rental_typology
        crrem_typology
        current_synoptic_quality_reporting
        current_typology
        declared_detention_regime
        declared_ef_rental_areas
        declared_rental_typology
        deet_geographic_zone
        deet_subject
        deet_subject_case
        detention_contextual_information
        discontinued_data_questions
        disposal_date
        dpe
        dpe_emissions
        dpe_energy
        efa_type
        energisme_energy_data_type
        energy_data_upload_possibilities
        energy_oid_ref_2022
        energy_perf_oid_ref
        energy_quality_data_2023
        esg_modality {
          name
          __typename
        }
        exhaustiveness_remaining_questions
        exploitation_certification
        fluids_typology
        functional_entities {
          code
          lots
          surface
          tenant_code
          tenant_name
          type
          __typename
        }
        functional_entities_state_date
        ges_missions_oid_ref
        ges_oid_ref
        google_maps_url
        gtb_presence
        indoor_parking
        initial_building_permit_date
        irves_presence
        last_dpe_date
        last_known_dpe
        last_renovation_date
        last_reporting_period_surface_consumption
        last_reporting_period_surface_consumption_n_1
        latitude
        longitude
        maintainers_intervenants_contact {
          email
          name
          organizations_names
          phone_number
          role
          __typename
        }
        maintenance_company
        mandates_questions
        name
        number_of_buildings
        number_of_buildings_client
        number_of_indoor_parkings
        number_of_outdoor_parkings
        number_of_potentially_subjected_functional_entities
        number_of_tenants_following_rental_state_ex_parking
        opening_hours
        organization_type
        organizations {
          name
          type
          __typename
        }
        outdoor_parking
        perimeter_2023
        photovoltaic_presence
        postal_code
        powerpoint_synoptic
        previous_year_synoptic_validation
        property_manager_agency {
          name
          type
          __typename
        }
        property_manager_contact {
          email
          name
          organizations_names
          phone_number
          role
          __typename
        }
        reference_weather_station_city
        remaining_site_questions
        rental_manager_contact {
          email
          name
          organizations_names
          phone_number
          role
          __typename
        }
        reporting_2023_surface_consumption
        reporting_2023_total_consumption
        reporting_2023_total_ghg_emissions
        reporting_2023_total_water_consumption
        reporting_end_date
        reporting_start_date
        rie_exploitation {
          name
          type
          __typename
        }
        rie_presence
        stakeholders_update_date
        synoptic_quality_reporting_2023
        synoptic_validation_note_2022_2023
        technical_assistant_contact {
          email
          name
          organizations_names
          phone_number
          role
          __typename
        }
        technical_contact {
          email
          name
          organizations_names
          phone_number
          role
          __typename
        }
        technical_documentation_bacs
        technical_documentation_irves
        technical_documentation_miscelleanous
        technical_documentation_pacs
        technical_documentation_photovoltaic
        technical_documentation_rma
        technical_documentation_technical_written_elements
        technical_documentation_vacs
        thermal_regulation
        third_party_parts_supply
        unified_degree_day_weather_station {
          city
          code
          __typename
        }
        updated_previous_year_tracking
        updated_year_tracking
        validated_client_hours
        validated_client_surface
        validated_client_working_days
        working_days
        year_synoptic_validation
        __typename
      }
      analysis {
        general {
          created_at
          text
          __typename
        }
        __typename
      }
      id
      name
      portfolioId
      properties {
        id
        name
        quality {
          completenes_end_date
          completenes_start_date
          completeness
          completeness_end_date
          completeness_start_date
          ignored_intervals {
            end_date
            start_date
            __typename
          }
          missing_values
          outliers {
            timestamp
            unit
            value
            __typename
          }
          overall_quality
          overall_quality_reason
          timeseries_end_date
          timeseries_start_date
          __typename
        }
        unit
        value
        __typename
      }
      __typename
    }
  }
`;
export const getSiteAggElecTimeseries = /* GraphQL */ `
  query GetSiteAggElecTimeseries($id: ID!, $time: AWSDateTimeInput) {
    getSiteAggElecTimeseries(id: $id, time: $time) {
      deviceId
      id
      nextToken
      pagination {
        nextToken
        startedAt
        __typename
      }
      propertyId
      timeseries {
        grid_name
        monotone_cumulative_time_in_min
        monotone_end_date
        monotone_execution_time
        monotone_start_date
        nature
        reason
        sampling_rate_in_min
        source
        time
        value
        value_nature_absence
        value_nature_actual
        value_nature_brut
        value_nature_corrected
        value_nature_corrige
        value_nature_coupure_secteur
        value_nature_coupure_secteur_courte
        value_nature_debut_de_coupure_secteur
        value_nature_esti
        value_nature_estimated
        value_nature_estime
        value_nature_fin_de_coupure_secteur
        value_nature_importe_manuellement_par_le_metier_enedis
        value_nature_no_data
        value_nature_puissance_reconstituee_et_coupure_secteur
        value_nature_reel
        value_nature_regu
        value_source_airtable_manual_bills
        value_source_airtable_third_party_active_power_in_kwh
        value_source_airtable_third_party_energy
        value_source_enedis_active_power_daily_in_kwh
        value_source_enedis_billed_energy
        __typename
      }
      unit
      __typename
    }
  }
`;
export const getSiteDevicesTimeseries = /* GraphQL */ `
  query GetSiteDevicesTimeseries(
    $id: ID!
    $nextToken: String
    $time: AWSDateTimeInput
  ) {
    getSiteDevicesTimeseries(id: $id, nextToken: $nextToken, time: $time) {
      items {
        deviceId
        id
        nextToken
        pagination {
          nextToken
          startedAt
          __typename
        }
        propertyId
        timeseries {
          grid_name
          monotone_cumulative_time_in_min
          monotone_end_date
          monotone_execution_time
          monotone_start_date
          nature
          reason
          sampling_rate_in_min
          source
          time
          value
          value_nature_absence
          value_nature_actual
          value_nature_brut
          value_nature_corrected
          value_nature_corrige
          value_nature_coupure_secteur
          value_nature_coupure_secteur_courte
          value_nature_debut_de_coupure_secteur
          value_nature_esti
          value_nature_estimated
          value_nature_estime
          value_nature_fin_de_coupure_secteur
          value_nature_importe_manuellement_par_le_metier_enedis
          value_nature_no_data
          value_nature_puissance_reconstituee_et_coupure_secteur
          value_nature_reel
          value_nature_regu
          value_source_airtable_manual_bills
          value_source_airtable_third_party_active_power_in_kwh
          value_source_airtable_third_party_energy
          value_source_enedis_active_power_daily_in_kwh
          value_source_enedis_billed_energy
          __typename
        }
        unit
        __typename
      }
      nextToken
      scannedCount
      startedAt
      __typename
    }
  }
`;
export const getSiteDjrTimeseries = /* GraphQL */ `
  query GetSiteDjrTimeseries($id: ID!, $time: AWSDateTimeInput) {
    getSiteDjrTimeseries(id: $id, time: $time) {
      deviceId
      id
      nextToken
      pagination {
        nextToken
        startedAt
        __typename
      }
      propertyId
      timeseries {
        grid_name
        monotone_cumulative_time_in_min
        monotone_end_date
        monotone_execution_time
        monotone_start_date
        nature
        reason
        sampling_rate_in_min
        source
        time
        value
        value_nature_absence
        value_nature_actual
        value_nature_brut
        value_nature_corrected
        value_nature_corrige
        value_nature_coupure_secteur
        value_nature_coupure_secteur_courte
        value_nature_debut_de_coupure_secteur
        value_nature_esti
        value_nature_estimated
        value_nature_estime
        value_nature_fin_de_coupure_secteur
        value_nature_importe_manuellement_par_le_metier_enedis
        value_nature_no_data
        value_nature_puissance_reconstituee_et_coupure_secteur
        value_nature_reel
        value_nature_regu
        value_source_airtable_manual_bills
        value_source_airtable_third_party_active_power_in_kwh
        value_source_airtable_third_party_energy
        value_source_enedis_active_power_daily_in_kwh
        value_source_enedis_billed_energy
        __typename
      }
      unit
      __typename
    }
  }
`;
export const getSiteDjuTimeseries = /* GraphQL */ `
  query GetSiteDjuTimeseries($id: ID!, $time: AWSDateTimeInput) {
    getSiteDjuTimeseries(id: $id, time: $time) {
      deviceId
      id
      nextToken
      pagination {
        nextToken
        startedAt
        __typename
      }
      propertyId
      timeseries {
        grid_name
        monotone_cumulative_time_in_min
        monotone_end_date
        monotone_execution_time
        monotone_start_date
        nature
        reason
        sampling_rate_in_min
        source
        time
        value
        value_nature_absence
        value_nature_actual
        value_nature_brut
        value_nature_corrected
        value_nature_corrige
        value_nature_coupure_secteur
        value_nature_coupure_secteur_courte
        value_nature_debut_de_coupure_secteur
        value_nature_esti
        value_nature_estimated
        value_nature_estime
        value_nature_fin_de_coupure_secteur
        value_nature_importe_manuellement_par_le_metier_enedis
        value_nature_no_data
        value_nature_puissance_reconstituee_et_coupure_secteur
        value_nature_reel
        value_nature_regu
        value_source_airtable_manual_bills
        value_source_airtable_third_party_active_power_in_kwh
        value_source_airtable_third_party_energy
        value_source_enedis_active_power_daily_in_kwh
        value_source_enedis_billed_energy
        __typename
      }
      unit
      __typename
    }
  }
`;
export const getSiteTimeseries = /* GraphQL */ `
  query GetSiteTimeseries(
    $aggregation: AggregationTimeseries
    $id: ID!
    $nextToken: String
    $time: AWSDateTimeInput
  ) {
    getSiteTimeseries(
      aggregation: $aggregation
      id: $id
      nextToken: $nextToken
      time: $time
    ) {
      items {
        aggDestination {
          deposited {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          out_of_scope {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          private {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          private_vacant {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          private_vacant_shared {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          shared {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          sum
          third_party {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          __typename
        }
        aggFluid {
          butane {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          coal {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          cold {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          electricity {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          fod {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          gaz {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          heat {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          otherFossileFuel {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          propane {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          sum
          water {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          wood {
            value
            value_reason_airtable_manual_bills
            value_reason_third_party_energy
            __typename
          }
          __typename
        }
        aggFluidDestination {
          butane {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          coal {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          cold {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          electricity {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          fod {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          gaz {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          heat {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          otherFossileFuel {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          propane {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          sum {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          water {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          wood {
            deposited {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            out_of_scope {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            private_vacant_shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            shared {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            sum
            third_party {
              value
              value_reason_airtable_manual_bills
              value_reason_third_party_energy
              __typename
            }
            __typename
          }
          __typename
        }
        id
        time
        unit
        value
        __typename
      }
      nextToken
      scannedCount
      startedAt
      __typename
    }
  }
`;
export const getSubdevice = /* GraphQL */ `
  query GetSubdevice($id: ID!) {
    getSubdevice(id: $id) {
      building
      contract_holder
      description
      deviceId
      id
      lessor
      name
      siteId
      __typename
    }
  }
`;
export const getSubdeviceTimeseries = /* GraphQL */ `
  query GetSubdeviceTimeseries(
    $id: ID!
    $nextToken: String
    $time: AWSDateTimeInput
  ) {
    getSubdeviceTimeseries(id: $id, nextToken: $nextToken, time: $time) {
      deviceId
      id
      nextToken
      pagination {
        nextToken
        startedAt
        __typename
      }
      propertyId
      timeseries {
        grid_name
        monotone_cumulative_time_in_min
        monotone_end_date
        monotone_execution_time
        monotone_start_date
        nature
        reason
        sampling_rate_in_min
        source
        time
        value
        value_nature_absence
        value_nature_actual
        value_nature_brut
        value_nature_corrected
        value_nature_corrige
        value_nature_coupure_secteur
        value_nature_coupure_secteur_courte
        value_nature_debut_de_coupure_secteur
        value_nature_esti
        value_nature_estimated
        value_nature_estime
        value_nature_fin_de_coupure_secteur
        value_nature_importe_manuellement_par_le_metier_enedis
        value_nature_no_data
        value_nature_puissance_reconstituee_et_coupure_secteur
        value_nature_reel
        value_nature_regu
        value_source_airtable_manual_bills
        value_source_airtable_third_party_active_power_in_kwh
        value_source_airtable_third_party_energy
        value_source_enedis_active_power_daily_in_kwh
        value_source_enedis_billed_energy
        __typename
      }
      unit
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!, $username: String!) {
    getUser(id: $id, username: $username) {
      email
      enabled
      id
      perimeter {
        devices
        sites
        __typename
      }
      role
      timeConstraintPerimeter {
        devices {
          id
          periods {
            endDate
            startDate
            __typename
          }
          __typename
        }
        sites {
          id
          periods {
            endDate
            startDate
            __typename
          }
          __typename
        }
        __typename
      }
      username
      __typename
    }
  }
`;
export const listBuildings = /* GraphQL */ `
  query ListBuildings(
    $filter: BuildingFilterInput
    $limit: Int
    $sort: SortInput
  ) {
    listBuildings(filter: $filter, limit: $limit, sort: $sort) {
      items {
        Devices {
          items {
            airtable {
              analog_periods_without_data
              authorization_end_date
              automatic_upload
              building_tenths {
                building_tenth
                distribution_tenth
                distribution_tenth_comment
                organisation_tenant
                property_owner_tenth
                site_code
                site_id
                tenth
                __typename
              }
              consumption_2010_10years_in_kwh_pcs
              consumption_2011_10years_in_kwh_pcs
              consumption_2012_10years_in_kwh_pcs
              consumption_2013_10years_in_kwh_pcs
              consumption_2014_10years_in_kwh_pcs
              consumption_2015_10years_in_kwh_pcs
              consumption_2016_10years_in_kwh_pcs
              consumption_2017_10years_in_kwh_pcs
              consumption_2018_10years_in_kwh_pcs
              consumption_2019_10years_in_kwh_pcs
              consumption_2020_10years_in_kwh_pcs
              consumption_2021_10years_in_kwh_pcs
              consumption_2022_10years_in_kwh_pcs
              contextual_information
              contract_holder
              current_synoptic_quality_reporting
              customer_space_available
              deet_category
              delegated_authorization
              deposited
              destination
              distributor_name
              energy_data_upload_possibilities
              fluid_type
              functional_entities {
                code
                lots
                surface
                tenant_code
                tenant_name
                type
                __typename
              }
              last_analog_invoice_date
              named_authorization
              pdl_id
              placement
              quality_of_data_completeness_2023
              questions_about_pdl_to_energy_manager
              site_code
              site_name
              sites {
                address
                city
                code
                current_synoptic_quality_reporting
                id
                name
                organisation_tenant
                postal_code
                __typename
              }
              sub_metering
              supplier_name
              tenth
              unique_point
              usage
              __typename
            }
            analysis {
              meter_commissioning {
                created_at
                text
                __typename
              }
              __typename
            }
            buildingId
            deviceType
            fusionedEnergyQuality
            id
            name
            properties {
              id
              name
              quality {
                completenes_end_date
                completenes_start_date
                completeness
                completeness_end_date
                completeness_start_date
                ignored_intervals {
                  end_date
                  start_date
                  __typename
                }
                missing_values
                outliers {
                  timestamp
                  unit
                  value
                  __typename
                }
                overall_quality
                overall_quality_reason
                timeseries_end_date
                timeseries_start_date
                __typename
              }
              unit
              value
              __typename
            }
            siteCode
            siteCodes
            siteId
            siteIds
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        id
        location {
          address
          city
          country
          latitude
          longitude
          zipcode
          __typename
        }
        name
        portfolioId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: DeviceFilterInput
    $limit: Int
    $nextToken: String
    $sort: SortInput
  ) {
    listDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        airtable {
          analog_periods_without_data
          authorization_end_date
          automatic_upload
          building_tenths {
            building_tenth
            distribution_tenth
            distribution_tenth_comment
            organisation_tenant
            property_owner_tenth
            site_code
            site_id
            tenth
            __typename
          }
          consumption_2010_10years_in_kwh_pcs
          consumption_2011_10years_in_kwh_pcs
          consumption_2012_10years_in_kwh_pcs
          consumption_2013_10years_in_kwh_pcs
          consumption_2014_10years_in_kwh_pcs
          consumption_2015_10years_in_kwh_pcs
          consumption_2016_10years_in_kwh_pcs
          consumption_2017_10years_in_kwh_pcs
          consumption_2018_10years_in_kwh_pcs
          consumption_2019_10years_in_kwh_pcs
          consumption_2020_10years_in_kwh_pcs
          consumption_2021_10years_in_kwh_pcs
          consumption_2022_10years_in_kwh_pcs
          contextual_information
          contract_holder
          current_synoptic_quality_reporting
          customer_space_available
          deet_category
          delegated_authorization
          deposited
          destination
          distributor_name
          energy_data_upload_possibilities
          fluid_type
          functional_entities {
            code
            lots
            surface
            tenant_code
            tenant_name
            type
            __typename
          }
          last_analog_invoice_date
          named_authorization
          pdl_id
          placement
          quality_of_data_completeness_2023
          questions_about_pdl_to_energy_manager
          site_code
          site_name
          sites {
            address
            city
            code
            current_synoptic_quality_reporting
            id
            name
            organisation_tenant
            postal_code
            __typename
          }
          sub_metering
          supplier_name
          tenth
          unique_point
          usage
          __typename
        }
        analysis {
          meter_commissioning {
            created_at
            text
            __typename
          }
          __typename
        }
        buildingId
        deviceType
        fusionedEnergyQuality
        id
        name
        properties {
          id
          name
          quality {
            completenes_end_date
            completenes_start_date
            completeness
            completeness_end_date
            completeness_start_date
            ignored_intervals {
              end_date
              start_date
              __typename
            }
            missing_values
            outliers {
              timestamp
              unit
              value
              __typename
            }
            overall_quality
            overall_quality_reason
            timeseries_end_date
            timeseries_start_date
            __typename
          }
          unit
          value
          __typename
        }
        siteCode
        siteCodes
        siteId
        siteIds
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listFunctionalEntities = /* GraphQL */ `
  query ListFunctionalEntities(
    $filter: FunctionalEntityFilterInput
    $limit: Int
    $sort: SortInput
  ) {
    listFunctionalEntities(filter: $filter, limit: $limit, sort: $sort) {
      items {
        airtable {
          analog_periods_without_data
          authorization_end_date
          automatic_upload
          building_tenths {
            building_tenth
            distribution_tenth
            distribution_tenth_comment
            organisation_tenant
            property_owner_tenth
            site_code
            site_id
            tenth
            __typename
          }
          consumption_2010_10years_in_kwh_pcs
          consumption_2011_10years_in_kwh_pcs
          consumption_2012_10years_in_kwh_pcs
          consumption_2013_10years_in_kwh_pcs
          consumption_2014_10years_in_kwh_pcs
          consumption_2015_10years_in_kwh_pcs
          consumption_2016_10years_in_kwh_pcs
          consumption_2017_10years_in_kwh_pcs
          consumption_2018_10years_in_kwh_pcs
          consumption_2019_10years_in_kwh_pcs
          consumption_2020_10years_in_kwh_pcs
          consumption_2021_10years_in_kwh_pcs
          consumption_2022_10years_in_kwh_pcs
          contextual_information
          contract_holder
          current_synoptic_quality_reporting
          customer_space_available
          deet_category
          delegated_authorization
          deposited
          destination
          distributor_name
          energy_data_upload_possibilities
          fluid_type
          functional_entities {
            code
            lots
            surface
            tenant_code
            tenant_name
            type
            __typename
          }
          last_analog_invoice_date
          named_authorization
          pdl_id
          placement
          quality_of_data_completeness_2023
          questions_about_pdl_to_energy_manager
          site_code
          site_name
          sites {
            address
            city
            code
            current_synoptic_quality_reporting
            id
            name
            organisation_tenant
            postal_code
            __typename
          }
          sub_metering
          supplier_name
          tenth
          unique_point
          usage
          __typename
        }
        buildingId
        id
        name
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listPortfolios = /* GraphQL */ `
  query ListPortfolios(
    $filter: PortfolioFilterInput
    $limit: Int
    $sort: SortInput
  ) {
    listPortfolios(filter: $filter, limit: $limit, sort: $sort) {
      items {
        id
        name
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: SiteFilterInput
    $limit: Int
    $nextToken: String
    $sort: SortInput
  ) {
    listSites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        airtable {
          acquisition_date
          address
          aegeon_energy_data_type
          altitude
          asl_aful_names
          asl_and_aful {
            name
            type
            __typename
          }
          asset_manager_contact {
            email
            name
            organizations_names
            phone_number
            role
            __typename
          }
          billing_contact {
            email
            name
            organizations_names
            phone_number
            role
            __typename
          }
          biodiversity_label
          building_site_plan
          city
          closing_hours
          co_ownership {
            name
            type
            __typename
          }
          co_ownership_name
          code
          commissioning_and_reporting_note_2023
          construction_certification
          construction_date
          consumption_or_production
          counted_rental_typology
          crrem_typology
          current_synoptic_quality_reporting
          current_typology
          declared_detention_regime
          declared_ef_rental_areas
          declared_rental_typology
          deet_geographic_zone
          deet_subject
          deet_subject_case
          detention_contextual_information
          discontinued_data_questions
          disposal_date
          dpe
          dpe_emissions
          dpe_energy
          efa_type
          energisme_energy_data_type
          energy_data_upload_possibilities
          energy_oid_ref_2022
          energy_perf_oid_ref
          energy_quality_data_2023
          esg_modality {
            name
            __typename
          }
          exhaustiveness_remaining_questions
          exploitation_certification
          fluids_typology
          functional_entities {
            code
            lots
            surface
            tenant_code
            tenant_name
            type
            __typename
          }
          functional_entities_state_date
          ges_missions_oid_ref
          ges_oid_ref
          google_maps_url
          gtb_presence
          indoor_parking
          initial_building_permit_date
          irves_presence
          last_dpe_date
          last_known_dpe
          last_renovation_date
          last_reporting_period_surface_consumption
          last_reporting_period_surface_consumption_n_1
          latitude
          longitude
          maintainers_intervenants_contact {
            email
            name
            organizations_names
            phone_number
            role
            __typename
          }
          maintenance_company
          mandates_questions
          name
          number_of_buildings
          number_of_buildings_client
          number_of_indoor_parkings
          number_of_outdoor_parkings
          number_of_potentially_subjected_functional_entities
          number_of_tenants_following_rental_state_ex_parking
          opening_hours
          organization_type
          organizations {
            name
            type
            __typename
          }
          outdoor_parking
          perimeter_2023
          photovoltaic_presence
          postal_code
          powerpoint_synoptic
          previous_year_synoptic_validation
          property_manager_agency {
            name
            type
            __typename
          }
          property_manager_contact {
            email
            name
            organizations_names
            phone_number
            role
            __typename
          }
          reference_weather_station_city
          remaining_site_questions
          rental_manager_contact {
            email
            name
            organizations_names
            phone_number
            role
            __typename
          }
          reporting_2023_surface_consumption
          reporting_2023_total_consumption
          reporting_2023_total_ghg_emissions
          reporting_2023_total_water_consumption
          reporting_end_date
          reporting_start_date
          rie_exploitation {
            name
            type
            __typename
          }
          rie_presence
          stakeholders_update_date
          synoptic_quality_reporting_2023
          synoptic_validation_note_2022_2023
          technical_assistant_contact {
            email
            name
            organizations_names
            phone_number
            role
            __typename
          }
          technical_contact {
            email
            name
            organizations_names
            phone_number
            role
            __typename
          }
          technical_documentation_bacs
          technical_documentation_irves
          technical_documentation_miscelleanous
          technical_documentation_pacs
          technical_documentation_photovoltaic
          technical_documentation_rma
          technical_documentation_technical_written_elements
          technical_documentation_vacs
          thermal_regulation
          third_party_parts_supply
          unified_degree_day_weather_station {
            city
            code
            __typename
          }
          updated_previous_year_tracking
          updated_year_tracking
          validated_client_hours
          validated_client_surface
          validated_client_working_days
          working_days
          year_synoptic_validation
          __typename
        }
        analysis {
          general {
            created_at
            text
            __typename
          }
          __typename
        }
        id
        name
        portfolioId
        properties {
          id
          name
          quality {
            completenes_end_date
            completenes_start_date
            completeness
            completeness_end_date
            completeness_start_date
            ignored_intervals {
              end_date
              start_date
              __typename
            }
            missing_values
            outliers {
              timestamp
              unit
              value
              __typename
            }
            overall_quality
            overall_quality_reason
            timeseries_end_date
            timeseries_start_date
            __typename
          }
          unit
          value
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listSubdevices = /* GraphQL */ `
  query ListSubdevices(
    $filter: SubDeviceFilterInput!
    $limit: Int
    $nextToken: String
    $sort: SortInput
  ) {
    listSubdevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        building
        contract_holder
        description
        deviceId
        id
        lessor
        name
        siteId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: UserFilterInput) {
    listUsers(filter: $filter) {
      items {
        email
        enabled
        id
        perimeter {
          devices
          sites
          __typename
        }
        role
        timeConstraintPerimeter {
          devices {
            id
            periods {
              endDate
              startDate
              __typename
            }
            __typename
          }
          sites {
            id
            periods {
              endDate
              startDate
              __typename
            }
            __typename
          }
          __typename
        }
        username
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
