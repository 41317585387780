import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import InfoTooltipIcon from "../../../assets/InfoTooltipIcon";
import globalUseStyles from '../../../styles/globalCustomStyles';
import CustomTooltip from '../../aegeon/CustomTooltip';
import ChartProfile from '../../aegeon/charts/ChartProfile';
import ChartTenYearsTable from '../../aegeon/charts/ChartTenYearsTable';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import ChartDeviceTable from '../Cards/ChartDeviceTable';

const TableDetailsComponent = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const { source, isLocked, setDataSourcesCallback } = props;
  const type = props.type;
  const tooltip_text = isLocked ? t('devices.sources.tooltip_locked') : source.tooltip_text;
  const origin_subtitle = t('devices.sources.origin_subtitle');
  const [subtitle, setSubtitle] = useState(type === "origin" ? origin_subtitle : "");

  const isLoadingCallBack = (isLoading) => {
    props.setIsLoading && props.setIsLoading(isLoading)
  }

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitle(childData);
    }
  };

  const child = (type === "ten_years" ?
    <ChartTenYearsTable
      setSubtitleCallback={handleSetSubtitleCallback}
      setDataCallback={(childData) => setDataSourcesCallback && setDataSourcesCallback(childData, source.source)}
    />
    : type === "origin" ?
      <div style={{ width: "100%", height: "200px" }} >
        <TimeseriesField source={source.source} filter={source.filter} aggregation={source.aggregation}>
          <ChartProfile isZoom={false} />
        </TimeseriesField>
      </div> :
      <TimeseriesField source={source.source} filter={source.filter} aggregation={source.aggregation}>
        <ChartDeviceTable
          source={source.source}
          unit={source.unit}
          displayButton={false}
          groupBy="month"
          border={true}
          className={globalClasses.DetailCardTableCells}
          setSubtitleCallback={handleSetSubtitleCallback}
          setDataCallback={(childData) => setDataSourcesCallback && setDataSourcesCallback(childData, source.source)}
          isLocked={isLocked}
          isDetailed={true}
          isLoadingCallBack={isLoadingCallBack}
          record={props.record}
        />
      </TimeseriesField>
  )

  return (
    <>
      <div className={globalClasses.DetailCardTableTitle}>
        <Typography variant="h8">{source.name}</Typography>
        {tooltip_text && <CustomTooltip title={tooltip_text} icon={<InfoTooltipIcon />} />}
      </div>
      <div className={globalClasses.DetailCardTableSubtitle}>
        {subtitle}
      </div>
      <div style={{ minHeight: "200px" }}>
        {child}
      </div >
    </>
  )
};


export default TableDetailsComponent;

