// Libs
import React, { useEffect, useState } from 'react';
import { useTranslate, useNotify, useRefresh } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Chip, Tooltip } from '@mui/material';
import useMutationProvider from '../../aegeon/mutation/useMutationProvider';
import {
  useGetList,
} from 'react-admin';
import { utils, writeFile } from "xlsx";
import moment from 'moment';

// Material UI components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Custom Style
import globalUseStyles from '../../../styles/globalCustomStyles';
import BottomButton from '../../Buttons/BottomButton';

const SyncPagePreview = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const navigate = useNavigate();
  const newUsers = props.newUsers;
  const notify = useNotify();
  const [evaluatedUsers, setEvaluatedUsers] = useState(null);
  const refresh = useRefresh();

  const { data: sites } = useGetList('sites');

  const { data: devices } = useGetList('devices');

  const convertDate = (date) => {
    if (!date) {
      return t('users.synchronize.no_date');
    }
    let timezone = 'Europe/Paris';
    let dateWithTimezone = new Date(date);
    dateWithTimezone.setHours(0, 0, 0, 0);
    dateWithTimezone = new Date(dateWithTimezone.toLocaleString('en-US', { timeZone: timezone }))
    return dateWithTimezone.toLocaleDateString('fr-FR');
  };

  const handleCancelClick = () => {
    navigate('/users');
  };

  const onErrorCallback = (error) => {
    notify(error.message, { type: 'error' });
    navigate('/users');
  };

  const exportPasswordsToExcel = (passwords) => {
    let passwords_data = [["username", "password"]];
    passwords.forEach((password) => {
      passwords_data.push([password.username, password.password]);
    });
    let sheets_data = [{ sheetName: 'Nouveaux Utilisateurs', data: passwords_data }];
    var wb = utils.book_new();
    if (sheets_data.length > 0) {
      sheets_data.forEach((sheet) => {
        var ws = utils.aoa_to_sheet(sheet.data);
        let nb_of_cols = sheet.data[0].length;
        let wscols = [];
        for (let i = 0; i < nb_of_cols; i++) {
          wscols.push({ wch: 20 });
        }
        ws['!cols'] = wscols;
        utils.book_append_sheet(wb, ws, sheet.sheetName);
      });
      writeFile(wb, 'passwords.xlsx');
    }
  };

  const onSuccessCallback = (data, variables) => {
    if (data.data===undefined) {
      notify('users.synchronize.error', { type: 'error' });
      navigate('/users');
      return;
    }
    if (variables?.input?.operation === 'evaluate') {
      setEvaluatedUsers(data);
    } else {
      if (data.data?.passwords && data.data?.passwords.length > 0) {
        exportPasswordsToExcel(data.data?.passwords);
      }
      refresh();
      notify('users.synchronize.success', { type: 'success' });
      navigate('/users');
    }
  };

  const mutation = useMutationProvider(
    'syncUsers',
    {
      input: {
        all_tenants: false,
        changed_users: [],
        new_users: [],
        operation: 'evaluate',
        same_users: [],
        source: 'api',
        users: [],
      },
    },
    onErrorCallback,
    onSuccessCallback
  );

  const convertDateToDateTime = (date) => {
    let timezone = 'Europe/Paris';
    let dateWithTimezone = new Date(date);
    dateWithTimezone.setHours(0, 0, 0, 0);
    dateWithTimezone = new Date(dateWithTimezone.toLocaleString('en-US', { timeZone: timezone }))
    return dateWithTimezone;
  }

  useEffect(() => {
    if (newUsers && !evaluatedUsers) {
      let usersSent = newUsers;
      usersSent.forEach((user) => {
        if (user.__typename) {
          delete user.__typename;
        }
        if (user.perimeter?.__typename) {
          delete user.perimeter.__typename;
        }
        if (!user.perimeter?.sites || user.perimeter?.sites?.length === 0) {
          user.perimeter.sites = null;
        }
        if (!user.perimeter?.devices || user.perimeter?.devices?.length === 0) {
          user.perimeter.devices = null;
        }
        if (!user.perimeter || (!user.perimeter.sites && !user.perimeter.devices)) {
          user.perimeter = null;
        }
        if (!user.timeConstraintPerimeter || user.timeConstraintPerimeter?.devices?.length === 0) {
          user.timeConstraintPerimeter = null;
        }
        if (user.perimeter?.sites) {
          if (user.perimeter.sites.includes('*')) {
            user.perimeter.sites = sites?.map((site) => site.id);
          } else {
            user.perimeter.sites = user.perimeter.sites
              .map((site) => sites?.find((s) => s.airtable.code === site)?.id)
              .filter((site) => site);
          }
        }
        if (user.perimeter?.devices) {
          if (user.perimeter.devices.includes('*')) {
            user.perimeter.devices = devices?.map((device) => device.id);
          } else {
            user.perimeter.devices = user.perimeter.devices
              .map((device) => devices?.find((d) => d.name === device)?.id)
              .filter((device) => device);
          }
        }
        if (user.timeConstraintPerimeter?.devices) {
          user.timeConstraintPerimeter.devices = user.timeConstraintPerimeter.devices?.map((device) => {
            device.periods = device?.periods.map((period) => {
              let { startDate, endDate } = period;
              startDate = startDate ? convertDateToDateTime(startDate) : null;
              endDate = endDate ? convertDateToDateTime(endDate) : null;
              return { startDate, endDate };
            });
            return {
              id: devices?.find((d) => d.name === device.id)?.id,
              periods: device.periods,
            };
          }
          );
        }
      });
      mutation.mutate({
        mutation_name: 'syncUsers',
        input: {
          all_tenants: false,
          operation: 'evaluate',
          source: 'api',
          users: usersSent,
        },
      });
    }
  }, [newUsers, evaluatedUsers]);

  const recursiveDeleteTypename = (obj) => {
    if (Array.isArray(obj)) {
      obj.forEach((value) => {
        if (value && typeof value === 'object') {
          recursiveDeleteTypename(value);
        }
      });
    } else {
      for (let key in obj) {
        if (key === '__typename') {
          delete obj[key];
        } else if (obj[key] && typeof obj[key] === 'object') {
          recursiveDeleteTypename(obj[key]);
        }
      }
    }
  };

  // TODO : get "all_tenants" from the form
  const handleApplyClick = () => {
    recursiveDeleteTypename(evaluatedUsers);
    let data = {
      all_tenants: false,
      changed_users: evaluatedUsers?.data?.changed_users || [],
      new_users: evaluatedUsers?.data?.new_users || [],
      operation: 'apply',
      same_users: evaluatedUsers?.data?.same_users || [],
      source: 'api',
      users: [],
    };
    mutation.mutate({ mutation_name: 'syncUsers', input: data });
  };

  const CustomTableRow = ({ index, row, className }) => (
    <TableRow key={index} className={className}>
      <TableCell component="th" scope="row">
        {row.username}
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{t(`users.general_information.profiles.${row.role}`)}</TableCell>
      <TableCell>
        {row.enabled ? t('users.general_information.enabled') : t('users.general_information.disabled')}
      </TableCell>
      <TableCell>
        {row.perimeter?.sites &&
          row.perimeter.sites.map((site) => (
            <Chip key={site} label={sites?.find((s) => s.id === site)?.airtable?.code} />
          ))}
      </TableCell>
      <TableCell>
        {row.perimeter?.devices &&
          row.perimeter.devices.map((device) => (
            <Chip key={device} label={devices?.find((d) => d.id === device)?.name} />
          ))}
      </TableCell>
      <TableCell>
        {row.timeConstraintPerimeter?.devices &&
          row.timeConstraintPerimeter.devices.map((device) => (
            <Tooltip title={t('users.synchronize.periods') + device.periods?.map((period) => t('users.synchronize.start_date') + convertDate(period?.startDate) + t('users.synchronize.end_date') + convertDate(period?.endDate) + '\n')} placement="top" className={globalClasses.oldValueTooltip}>
              <Chip key={device.id} label={devices?.find((d) => d.id === device.id)?.name} />
            </Tooltip>
          ))}
      </TableCell>
    </TableRow>
  );

  const CustomChangesTableRow = ({ key, user }) => {
    let new_sites = [];
    let removed_sites = [];
    let same_sites = [];
    let new_devices = [];
    let removed_devices = [];
    let same_devices = [];
    if (user?.changed_values?.perimeter) {
      let user_sites_new_values = user.changed_values?.perimeter?.new_value?.sites
      let user_sites_old_values = user.changed_values?.perimeter?.old_value?.sites
      let user_devices_new_values = user.changed_values?.perimeter?.new_value?.devices
      let user_devices_old_values = user.changed_values?.perimeter?.old_value?.devices

      new_sites = sites?.filter((site) => user_sites_new_values?.includes(site.id) && !user_sites_old_values?.includes(site.id))?.map((site) => site.airtable.code);
      removed_sites = sites?.filter((site) => user_sites_old_values?.includes(site.id) && !user_sites_new_values?.includes(site.id))?.map((site) => site.airtable.code);
      same_sites = sites?.filter((site) => user_sites_old_values?.includes(site.id) && user_sites_new_values?.includes(site.id))?.map((site) => site.airtable.code);

      new_devices = devices?.filter((device) => user_devices_new_values?.includes(device.id) && !user_devices_old_values?.includes(device.id))?.map((device) => device.name);
      removed_devices = devices?.filter((device) => user_devices_old_values?.includes(device.id) && !user_devices_new_values?.includes(device.id))?.map((device) => device.name);
      same_devices = devices?.filter((device) => user_devices_old_values?.includes(device.id) && user_devices_new_values?.includes(device.id))?.map((device) => device.name);
    }
    else {
      same_sites = user.same_values.perimeter?.sites?.map((site) => sites?.find(s => s.id === site)?.airtable.code);
      same_devices = user.same_values.perimeter?.devices?.map((device) => devices?.find(d => d.id === device)?.name);
    }

    var new_devices_with_timeconstraints = [];
    var removed_devices_with_timeconstraints = [];
    var same_devices_with_timeconstraints = [];

    if (user.changed_values?.timeConstraintPerimeter) {
      let devices_with_timeconstraints_new_values = user.changed_values?.timeConstraintPerimeter?.new_value?.devices
      let devices_with_timeconstraints_old_values = user.changed_values?.timeConstraintPerimeter?.old_value?.devices
      new_devices_with_timeconstraints = devices_with_timeconstraints_new_values?.filter(
        (device) => !devices_with_timeconstraints_old_values?.map((device) => device.id).includes(device.id)
      )?.map((device) =>  {return {"name": devices?.find(d => d.id === device.id)?.name, "periods": device.periods}} );
      removed_devices_with_timeconstraints = devices_with_timeconstraints_old_values?.filter(
        (device) => !devices_with_timeconstraints_new_values?.map((device) => device.id).includes(device.id)
      )?.map((device) => {return {"name": devices?.find(d => d.id === device.id)?.name, "periods": device.periods}} );
      same_devices_with_timeconstraints = devices_with_timeconstraints_new_values?.filter(
        (device) => devices_with_timeconstraints_old_values?.map((device) => device.id).includes(device.id)
      )?.map((device) =>  {return {"name": devices?.find(d => d.id === device.id)?.name, "periods": device.periods}} );
    }
    else {
      same_devices_with_timeconstraints = user.same_values.timeConstraintPerimeter?.devices?.map((device) =>  {return {"name": devices?.find(d => d.id === device.id)?.name, "periods": device.periods}} );
    }

    return (
      <TableRow key={key} className={globalClasses.changedUserRow}>
        <TableCell component="th" scope="row">
          {user?.username}
        </TableCell>
        <TableCell>
          {user.changed_values?.email?.new_value ? (
            <Tooltip
              title={t('users.synchronize.old_value_tooltip') + user.changed_values.email?.old_value}
              placement="top"
              className={globalClasses.oldValueTooltip}>
              <Chip label={user.changed_values.email.new_value} className={globalClasses.addedUserChip} />
            </Tooltip>
          ) : (
            user?.same_values?.email
          )}
        </TableCell>
        <TableCell>
          {user.changed_values?.role?.new_value ? (
            <Tooltip
              title={
                t('users.synchronize.old_value_tooltip') +
                t(`users.general_information.profiles.${user.changed_values.role?.old_value}`)
              }
              placement="top"
              className={globalClasses.oldValueTooltip}>
              <Chip
                label={t(`users.general_information.profiles.${user.changed_values?.role?.new_value}`)}
                className={globalClasses.addedUserChip}
              />
            </Tooltip>
          ) : (
            t(`users.general_information.profiles.${user.same_values.role}`)
          )}
        </TableCell>
        <TableCell>
          {user.changed_values?.enabled?.new_value !== undefined ? (
            <Tooltip
              title={
                t('users.synchronize.old_value_tooltip') +
                (user.same_values.enabled
                  ? t('users.general_information.enabled')
                  : t('users.general_information.disabled'))
              }
              placement="top"
              className={globalClasses.oldValueTooltip}>
              <Chip
                label={
                  user.changed_values.enabled.new_value === true
                    ? t('users.general_information.enabled')
                    : t('users.general_information.disabled')
                }
                className={globalClasses.addedUserChip}
              />
            </Tooltip>
          ) : user.same_values.enabled ? (
            t('users.general_information.enabled')
          ) : (
            t('users.general_information.disabled')
          )}
        </TableCell>
        <TableCell>
          {new_sites?.map((site) => (
            <Tooltip title={t('users.synchronize.new_value')} placement="top" className={globalClasses.oldValueTooltip}>
              <Chip key={site} label={site} className={globalClasses.addedUserChip} />
            </Tooltip>
          ))}
          {removed_sites?.map((site) => (
            <Tooltip title={t('users.synchronize.old_value')} placement="top" className={globalClasses.oldValueTooltip}>
              <Chip key={site} label={site} className={globalClasses.removedUserChip} />
            </Tooltip>
          ))}
          {same_sites?.map((site) => (
            <Chip key={site} label={site} className={globalClasses.sameUserChip} />
          ))}
        </TableCell>
        <TableCell>
          {new_devices?.map((device) => (
            <Tooltip title={t('users.synchronize.new_value')} placement="top" className={globalClasses.oldValueTooltip}>
              <Chip key={device} label={device} className={globalClasses.addedUserChip} />
            </Tooltip>
          ))}
          {removed_devices?.map((device) => (
            <Tooltip title={t('users.synchronize.old_value')} placement="top" className={globalClasses.oldValueTooltip}>
              <Chip key={device} label={device} className={globalClasses.removedUserChip} />
            </Tooltip>
          ))}{' '}
          {same_devices?.map((device) => (
            <Chip key={device} label={device} className={globalClasses.sameUserChip} />
          ))}
        </TableCell>
        <TableCell className={user.changed_values?.timeConstraintPerimeter ? globalClasses.changedUserTableCell : ''}>
          {new_devices_with_timeconstraints?.map((device) => (
            <Tooltip title={ t('users.synchronize.periods') + device?.periods?.map((period) =>t('users.synchronize.start_date') + convertDate(period?.startDate) + t('users.synchronize.end_date') + convertDate(period?.endDate) + '\n')}
             placement="top" className={globalClasses.oldValueTooltip}>
              <Chip key={device?.name} label={device?.name} className={globalClasses.addedUserChip} />
            </Tooltip>
          ))}
          {removed_devices_with_timeconstraints?.map((device) => (
            <Tooltip title={ t('users.synchronize.periods') + device?.periods?.map((period) =>t('users.synchronize.start_date') + convertDate(period?.startDate) + t('users.synchronize.end_date') + convertDate(period?.endDate) + '\n')}
            placement="top" className={globalClasses.oldValueTooltip}>
              <Chip key={device?.name} label={device?.name} className={globalClasses.removedUserChip} />
            </Tooltip>
          ))}{' '}
          {same_devices_with_timeconstraints?.map((device) => (
            <Tooltip title={ t('users.synchronize.periods') + device?.periods?.map((period) =>t('users.synchronize.start_date') + convertDate(period?.startDate) + t('users.synchronize.end_date') + convertDate(period?.endDate) + '\n')}
            placement="top" className={globalClasses.oldValueTooltip}>
              <Chip key={device?.name} label={device?.name} className={globalClasses.sameUserChip} />
            </Tooltip>
          ))}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <TableContainer component={Paper} className={globalClasses.usersSyncTable + ' ' + globalClasses.customChip}>
        {!sites || !devices ? (
          <></>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('users.general_information.name')}</TableCell>
                <TableCell>{t('users.general_information.email')}</TableCell>
                <TableCell>{t('users.general_information.profile')}</TableCell>
                <TableCell>{t('users.general_information.status')}</TableCell>
                <TableCell>{t('users.perimeter.sites')}</TableCell>
                <TableCell>{t('users.perimeter.devices')}</TableCell>
                <TableCell>{t('users.perimeter.devicesWithTimeConstraints')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {evaluatedUsers &&
                evaluatedUsers?.data?.changed_users.map((row, index) => (
                  <CustomChangesTableRow key={index} user={row} />
                ))}
              {evaluatedUsers &&
                evaluatedUsers?.data?.new_users.map((row, index) => (
                  <CustomTableRow key={index} row={row} className={globalClasses.newUserRow} />
                ))}
              {evaluatedUsers &&
                evaluatedUsers?.data?.same_users.map((row, index) => <CustomTableRow key={index} row={row} />)}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <div className={globalClasses.bottomButtonBar + ' ' + globalClasses.bottomButtonPage}>
        <BottomButton
          onClick={handleCancelClick}
          disabled={false}
          label={t('users.button.cancel')}
          className={globalClasses.cancelButton}
        />
        <BottomButton onClick={handleApplyClick} disabled={!evaluatedUsers} label={t('users.button.apply')} />
      </div>
    </>
  );
};

export default SyncPagePreview;
