import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { allowedShowComponents, useRolePermissions } from "../../aegeon-rbac/AuthRolePermissions";
import TableDJComponent from '../../aegeon/charts/TableDJComponent';
import QueryField from '../../aegeon/query/QueryField';
import TableDetailsComponent from './TableDetailsComponent';
import TableSubdevicesCard from './TableSubdevicesCard';

const TableCardDetailsAuto = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const [isLoadingDju, setIsLoadingDju] = useState(true);
  const [isLoadingDjr, setIsLoadingDjr] = useState(true);
  const [isLoadingFusioned, setIsLoadingFusioned] = useState(true);
  const [isLoadingBilled, setIsLoadingBilled] = useState(true);
  const [isLoadingActivePower, setIsLoadingActivePower] = useState(true);
  const [isLoadingManualBills, setIsLoadingManualBills] = useState(true);
  const [isLoadingThirdPartyEnergy, setIsLoadingThirdPartyEnergy] = useState(true);
  const [isLoadingThirdPartyActivePower, setIsLoadingThirdPartyActivePower] = useState(true);
  const { isLoading, permissions } = useRolePermissions();
  const setDisabled = props.setDisabled;

  const { record } = useContext(RecordContext);

  const moment = require('moment');

  const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const startDate10years = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter_all_data = { startDate: startDate10years, endDate: today };

  const empty_filter = { startDate: today, endDate: today };

  const isLocked = false;

  const billed_energy_source = record?.deviceType === 'enedisMeter' ? {
    name: t('devices.sources.enedis_billed_energy'),
    tooltip_text: t('devices.sources.enedis_billed_energy_tooltip'),
    source: 'billed_energy',
    filter: filter_all_data,
    aggregation: { period: 'MONTH', operation: 'SUM' },
    unit: record?.properties?.find((property) => property?.name === "billed_energy")?.unit,
  } : {
    name: t('devices.sources.grdf_billed_energy'),
    tooltip_text: t('devices.sources.grdf_billed_energy_tooltip'),
    source: 'billed_energy',
    filter: filter_all_data,
    aggregation: { period: 'MONTH', operation: 'SUM' },
    unit: record?.properties?.find((property) => property?.name === "billed_energy")?.unit,
  };

  const detailled_source = record?.deviceType === 'enedisMeter' ? {
    name: t('devices.sources.enedis_active_power_day_in_kwh'),
    tooltip_text: t('devices.sources.enedis_active_power_day_in_kwh_tooltip'),
    source: 'active_power',
    filter: filter_all_data,
    aggregation: { period: 'MONTH', operation: 'IN_KWH' },
    unit: undefined,
  } : {
    name: t('devices.sources.grdf_informative_energy'),
    tooltip_text: t('devices.sources.grdf_informative_energy_tooltip'),
    source: 'informative_energy',
    filter: filter_all_data,
    aggregation: { period: 'MONTH', operation: 'SUM' },
    unit: record?.properties?.find((property) => property?.name === "informative_energy")?.unit,
  };


  const sources = {
    fusioned_energy: {
      name: t('devices.sources.serie_aegeon'),
      tooltip_text: t('devices.sources.serie_aegeon_tooltip'),
      source: 'fusioned_energy',
      filter: filter_all_data,
      aggregation: { period: 'MONTH', operation: 'SUM' },
      unit: record?.properties?.find((property) => property?.name === "fusioned_energy")?.unit,
    },
    billed_energy: billed_energy_source,
    active_power: detailled_source,
    manual_bills: {
      name: t('devices.sources.manual_bills'),
      tooltip_text: t('devices.sources.manual_bills_tooltip'),
      source: 'manual_bills',
      filter: filter_all_data,
      aggregation: undefined,
      unit: record?.properties?.find((property) => property?.name === "manual_bills")?.unit,
    },
    third_party_energy: {
      name: t('devices.sources.third_party_energy'),
      tooltip_text: t('devices.sources.third_party_energy_tooltip'),
      source: 'third_party_energy',
      filter: filter_all_data,
      aggregation: undefined,
      unit: record?.properties?.find((property) => property?.name === "third_party_energy")?.unit,
    },
    third_party_active_power_in_kwh: {
      name: t('devices.sources.third_party_active_power_in_kwh'),
      tooltip_text: t('devices.sources.third_party_active_power_in_kwh_tooltip'),
      source: 'third_party_active_power_in_kwh',
      filter: filter_all_data,
      aggregation: undefined,
      unit: record?.properties?.find((property) => property?.name === "third_party_active_power_in_kwh")?.unit,
    },
    enedis_ten_years: {
      name: t('devices.sources.enedis_ten_years'),
      tooltip_text: t('devices.sources.enedis_ten_years_tooltip'),
      source: 'enedis_ten_years',
      filter: empty_filter,
      aggregation: undefined,
      unit: undefined,
    },
    origin: {
      name: t('devices.sources.origin'),
      tooltip_text: t('devices.sources.origin_tooltip'),
      source: 'fusioned_energy',
      filter: filter_all_data,
      aggregation: undefined,
      unit: undefined,
    },
    subdevice: {
      name: t('devices.sources.subdevice'),
      tooltip_text: t('devices.sources.subdevice_tooltip'),
      source: 'fusioned_energy',
      filter: filter_all_data,
      aggregation: undefined,
      unit: undefined,
    }
  }

  const djStartDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const djEndDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });


  useEffect(() => {
    if (!isLoadingActivePower && !isLoadingBilled && !isLoadingDjr && !isLoadingDju && !isLoadingFusioned && !isLoadingManualBills && !isLoadingThirdPartyEnergy && !isLoadingThirdPartyActivePower) {
      setDisabled && setDisabled(false);
    }
  }, [isLoadingDju, isLoadingDjr, isLoadingFusioned, isLoadingBilled, isLoadingActivePower, isLoadingManualBills, isLoadingThirdPartyEnergy, isLoadingThirdPartyActivePower, setDisabled]);

  const permissionControlledComponents = {
    resource: "devices",
    elements: {
      show: {
        "table.subdevices": <TableSubdevicesCard />
      }
    }
  };

  return (
    <>
      <Grid2 item="true" small={12} medium={12} big={12}>
        <Card className={globalClasses.DetailCard}>
          <Grid2 container direction="row" justifyContent="center" alignItems="center"
            spacing={5}
            style={{ padding: '0 !important' }}
          >
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["fusioned_energy"]} setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingFusioned} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["origin"]} type="origin" />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["billed_energy"]} setDataSourcesCallback={props.setDataSourcesCallback} isLocked={isLocked} setIsLoading={setIsLoadingBilled} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["active_power"]} setDataSourcesCallback={props.setDataSourcesCallback} isLocked={isLocked} setIsLoading={setIsLoadingActivePower} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["manual_bills"]} setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingManualBills} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["third_party_energy"]} setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingThirdPartyEnergy} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["third_party_active_power_in_kwh"]} setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingThirdPartyActivePower} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent source={sources["enedis_ten_years"]} setDataSourcesCallback={props.setDataSourcesCallback} type="ten_years" />
            </Grid2>
          </Grid2>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={12} >
        {!isLoading && allowedShowComponents(permissions, permissionControlledComponents)}
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={12}>
        <Card className={globalClasses.DetailCard}>
          <Grid2 container direction="row" justifyContent="center" alignItems="center"
            spacing={5}
            style={{ padding: '0 !important' }}
          >
            <Grid2 item="true" small={12} medium={12} big={6}>
              <QueryField query_name="getSiteDjuTimeseries" id={record?.siteIds?.length > 0 ? record?.siteIds[0] : record?.siteId} params={{ time: { between: [djStartDate, djEndDate] } }}>
                <TableDJComponent type="dju" setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingDju} />
              </QueryField>
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <QueryField query_name="getSiteDjrTimeseries" id={record?.siteIds?.length > 0 ? record?.siteIds[0] : record?.siteId} params={{ time: { between: [djStartDate, djEndDate] } }}>
                <TableDJComponent type="djr" setDataSourcesCallback={props.setDataSourcesCallback} setIsLoading={setIsLoadingDjr} />
              </QueryField>
            </Grid2>
          </Grid2>
        </Card>
      </Grid2>
    </>
  )
};

export default TableCardDetailsAuto;

