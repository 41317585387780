import React, { useContext, useEffect } from "react";
import { useTranslate } from 'react-admin';
import { RecordContext } from "../../../App";
import CustomLargeTable from "../../Table/CustomLargeTable";
import ChartNoData from "./ChartNoData";

const ChartTenYearsTable = (props) => {
    const { record } = useContext(RecordContext)
    const t = useTranslate();

    const convertData = (headers, values) => {
        if (values) {
            let data = [['Année'], ['Consommation (kWh)']]
            headers.forEach((header, index) => {
                data[0].push(header)
                data[1].push(values[index])
            })
            return data
        }
        return []
    }

    const headers = ["2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022"]
    const values = headers.map((header) => record && record?.airtable[`consumption_${header}_10years_in_kwh_pcs`] ? Math.round(record?.airtable[`consumption_${header}_10years_in_kwh_pcs`]) : "")

    useEffect(() => {
        values.some((value) => value !== "") && props.setSubtitleCallback(t("devices.sources.unit_prefix") + "kWh.")
        props.setDataCallback && props.setDataCallback(convertData(headers, values))
    }, [values]);

    return (
        values.some((value) => value !== "") ?
            <CustomLargeTable
                head={["", ...headers]}
                rows={[{ name: "Total", values: values }]}
                disableMissingDataTooltip={true}
                disabledBoldLastValue={true}
            />
            :
            <ChartNoData />
    )
}

export default ChartTenYearsTable;