import React, { useEffect, useState } from 'react';

import { useGetList, useTranslate } from 'react-admin';
import globalUseStyles from "../../../styles/globalCustomStyles";
import CardWrapper from "../../aegeon/cards/CardWrapper";
import QueryField from "../../aegeon/query/QueryField";
import QueryInfiniteField from "../../aegeon/query/QueryInfiniteField";
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import ChartSiteHorizontalBar from "./ChartSiteHorizontalBar";

const ChildComponentWithDjr = (props) => {
  const moment = require('moment');
  const startDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const { data, isLoading } = useQueryContext(props);

  return (
    <QueryInfiniteField query_name="getSiteTimeseries" params={{ time: { between: [startDate, endDate] } }}>
      <CardWrapper resource="sites" type="histogram" custom_tooltip={props.warningText} isRedWarning={props.qualityWarning === "red"} isOrangeWarning={props.qualityWarning === "orange"} >
        <ChartSiteHorizontalBar className={globalClasses.ChartCard} type="aggFluidDestination" hideLegend={true} dju={props.dju} djr={data} isLoading={isLoading} />
      </CardWrapper>
    </QueryInfiniteField>
  );
}

const ChildComponentWithDjuDjr = (props) => {
  const moment = require('moment');
  const startDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const { data, isLoading } = useQueryContext(props);

  return (
    <QueryField query_name="getSiteDjrTimeseries" params={{ time: { between: [startDate, endDate] } }}>
      <ChildComponentWithDjr dju={data} qualityWarning={props.qualityWarning} warningText={props.warningText} />
    </QueryField>
  );
}

const CardHistogram = (props) => {
  const moment = require('moment');
  const startDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const record = props.record;
  const siteId = record?.id;
  const t = useTranslate();
  const [qualityWarning, setQualityWarning] = useState(undefined)
  const [warningText, setWarningText] = useState(undefined)

  const { data: devices } = useGetList(
    'devices',
    {
      filter: {
        siteId: siteId
      },
    }
  );

  useEffect(() => {
    let quality_warning = devices?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'C') ? "red" : devices?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'B') ? "orange" : "green"
    setQualityWarning(quality_warning)
    setWarningText(t("sites.histogram.tooltip_text") + ((quality_warning === "red") ? t("sites.histogram.tooltip_red") : (quality_warning === "orange") ? t("sites.histogram.tooltip_orange") : t("sites.histogram.tooltip_green")))
  }, [devices])

  return (
    <QueryField query_name="getSiteDjuTimeseries" params={{ time: { between: [startDate, endDate] } }}>
      <ChildComponentWithDjuDjr qualityWarning={qualityWarning} warningText={warningText} />
    </QueryField>
  )
};

export default CardHistogram;