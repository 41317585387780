import { Card, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import ExclamationTooltipIcon from '../../../assets/ExclamationTooltipIcon';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { theme } from '../../../theme';
import CustomTooltip from '../../aegeon/CustomTooltip';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import QueryField from '../../aegeon/query/QueryField';
import QueryInfiniteField from '../../aegeon/query/QueryInfiniteField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import ChartSiteHorizontalBar from '../Cards/ChartSiteHorizontalBar';


const ChildComponentWithDjr = (props) => {
    const moment = require('moment');
    const t = useTranslate();
    const startDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const globalClasses = globalUseStyles();
    const { data, isLoading } = useQueryContext(props);
    const record = useRecordContext();
    const siteId = record?.id;
    const [qualityWarning, setQualityWarning] = React.useState(undefined)
    const [tooltipText, setTooltipText] = React.useState(undefined)

    const { data: devices } = useGetList(
        'devices',
        {
            filter: {
                siteId: siteId
            },
        }
    );

    if (!tooltipText) {
        let quality = devices?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'C') ? "red" : devices?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'B') ? "orange" : "green"
        setQualityWarning(quality)
        let tooltip_text = t("sites.histogram.tooltip_text") + ((quality === "red") ? t("sites.histogram.tooltip_red") : (quality === "orange") ? t("sites.histogram.tooltip_orange") : t("sites.histogram.tooltip_green"))
        setTooltipText(tooltip_text)
    }

    const CardDetailsHistogram = (props) => {
        const icon = qualityWarning === "red" ? <ExclamationTooltipIcon color={theme.palette.aegeon.danger} /> : qualityWarning === "orange" ? <ExclamationTooltipIcon color={theme.palette.aegeon.warning} /> : <InfoTooltipIcon />;
        return (
            <Card className={globalClasses.DetailCard}>
                <div className={globalClasses.DetailCardTableTitle}>
                    <Typography display="block" variant="h8">{props.title}</Typography>
                    {props.tooltip && <CustomTooltip title={props.tooltip} icon={icon} />}
                </div>
                <div className={globalClasses.DetailCardTableSubtitle} />
                <div className={globalClasses.DetailCardGraph} >
                    <ChartSiteHorizontalBar type={props.type} dju={props.dju} djr={props.djr} isLoadingDJ={isLoading} />
                </div>
            </Card>
        )
    }

    return (
        <QueryInfiniteField query_name="getSiteTimeseries" params={{ time: { between: [startDate, endDate] } }}>
            <Grid2 item="true" small={12} medium={12} big={6}>
                <CardDetailsHistogram type="aggFluidDestination" title={t('sites.histogram.aggFluidDestination.title')} dju={props.dju} djr={data} tooltip={tooltipText} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
                <CardDetailsHistogram type="aggFluid" title={t('sites.histogram.aggFluid.title')} dju={props.dju} djr={data} />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
                <CardDetailsHistogram type="aggDestination" title={t('sites.histogram.aggDestination.title')} dju={props.dju} djr={data} />
            </Grid2>
        </QueryInfiniteField>
    );
}

const ChildComponentWithDjuDjr = (props) => {
    const moment = require('moment');
    const startDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const { data } = useQueryContext(props);

    return (
        <QueryField query_name="getSiteDjrTimeseries" params={{ time: { between: [startDate, endDate] } }}>
            <ChildComponentWithDjr dju={data} />
        </QueryField>
    );
}


const SiteHistogramDetails = (props) => {
    const t = useTranslate();
    const moment = require('moment');
    const globalClasses = globalUseStyles();

    const startDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return (
        <DetailsShow title={t('sites.histogram.title')} resource="sites" lessMargin={true}>
            <Grid2 container spacing={2}>
                <QueryField query_name="getSiteDjuTimeseries" params={{ time: { between: [startDate, endDate] } }}>
                    <ChildComponentWithDjuDjr />
                </QueryField>
            </Grid2>
        </DetailsShow>
    )
};

export default SiteHistogramDetails;

