import { Box, Typography } from '@mui/material';
import React from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import DeviceIcon from '../../../assets/DeviceIcon';
import FunctionalEntityIcon from '../../../assets/FunctionalEntityIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ChartLoading from '../../aegeon/charts/ChartLoading';

const SiteComposition = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();

  const record = useRecordContext();
  const siteId = record?.id;

  const { data, isLoading } = useGetList(
    'devices',
    {
      filter: {
        siteIds: { contains: siteId },
      },
    }
  );


  if (isLoading === true) {
    return (
      <ChartLoading />
    );
  }

  const functional_entities_data = record?.airtable?.functional_entities ? record?.airtable?.functional_entities : [];
  let functional_entities_data_filtered = functional_entities_data.filter((ef) => (
    ["BUREAUX", "ACTIVITE", "COMMERCE"].includes(ef.type))
  )
  let devices_filtered = data?.filter((device) => (device.deviceType !== "unknownMeter" && device.airtable?.fluid_type !== "ERREUR"))

  // The rows tooltips are translated in the CustomTable component
  return (
    <div className={globalClasses.composition}>
      <Box className={globalClasses.compositionBox} >
        <div className={globalClasses.compositionNumber}>
          <FunctionalEntityIcon color='#6c74d8' />
          <Typography variant="h4" component="div" color='#6c74d8' className={globalClasses.compositionText}>
            {functional_entities_data_filtered?.length}
          </Typography>
        </div>
        <Typography variant="h9" component="div" className={globalClasses.compositionText}>
          {t('sites.composition.functional_entities_number')}
        </Typography>
      </Box>
      <Box className={globalClasses.compositionBox} >
        <div className={globalClasses.compositionNumber}>
          <DeviceIcon color="#4ecca2" />
          <Typography variant="h4" component="div" color="#4ecca2" className={globalClasses.compositionText}>
            {devices_filtered?.length}
          </Typography>

        </div>
        <Typography variant="h9" component="div" className={globalClasses.compositionText}>
          {t('sites.composition.devices_number')}
        </Typography>
      </Box>
    </div>
  )
}

export default SiteComposition;