const getGroup = (month, groupByTrimester) => {
  if (groupByTrimester) {
    return Math.floor(month / 3) + 1
  }
  return month + 1
}


const getTableSeries = (timeseries, groupByTrimester) => {
  let groupedTimeseries = {}
  timeseries.forEach(element => {
    let date = new Date(element.time)
    let group = getGroup(date.getMonth(), groupByTrimester)
    let year = date.getFullYear()
    let key = year
    let value = parseFloat(element.value)
    if (value || value === 0) {
      if (groupedTimeseries[key] === undefined) {
        groupedTimeseries[key] = {}
      }
      if (groupedTimeseries[key][group] === undefined) {
        groupedTimeseries[key][group] = value
      }
      else {
        groupedTimeseries[key][group] += value
      }
    }
  })
  groupedTimeseries = Object.keys(groupedTimeseries).sort().reverse().reduce(
    (obj, key) => {
      obj[key] = groupedTimeseries[key];
      return obj;
    },
    {}
  );

  if (groupByTrimester) {
    let years = Object.keys(groupedTimeseries)
    if (years.length > 5) {
      years = years.slice(years.length - 5, years.length)
      let newGroupedTimeseries = {}
      years.forEach(year => {
        newGroupedTimeseries[year] = groupedTimeseries[year]
      })
      groupedTimeseries = newGroupedTimeseries
    }
  }

  return groupedTimeseries
}

const getSerieAggFluidDestination = (timeseries, groupByTrimester) => {
  let groupedTimeseries = {}
  timeseries.forEach(element => {
    let date = new Date(element.time)
    let group = getGroup(date.getMonth(), groupByTrimester)
    let year = date.getFullYear()
    let key = year
    Object.keys(element.aggFluidDestination).forEach(fluid => {
      if (element.aggFluidDestination[fluid]) {
        Object.keys(element.aggFluidDestination[fluid]).forEach(destination => {
          let value = element.aggFluidDestination[fluid][destination]?.value
          if (value) {
            if (groupedTimeseries[fluid + '_' + destination] === undefined) {
              groupedTimeseries[fluid + '_' + destination] = {}
            }
            if (groupedTimeseries[fluid + '_' + destination][key] === undefined) {
              groupedTimeseries[fluid + '_' + destination][key] = {}
            }
            if (groupedTimeseries[fluid + '_' + destination][key][group] === undefined) {
              groupedTimeseries[fluid + '_' + destination][key][group] = value
            }
            else {
              groupedTimeseries[fluid + '_' + destination][key][group] += value
            }
          }
        })
      }
    })
  })
  Object.keys(groupedTimeseries).forEach(serie => {
    let serieToDelete = true
    Object.keys(groupedTimeseries[serie]).forEach(year => {
      Object.keys(groupedTimeseries[serie][year]).forEach(month => {
        if (groupedTimeseries[serie][year][month] !== 0) {
          serieToDelete = false
        }
      })
    })
    if (serieToDelete) {
      delete groupedTimeseries[serie]
    }
  })
  return groupedTimeseries
}



export { getTableSeries, getSerieAggFluidDestination}