// Libs
import React, { useState } from "react";
import { Form, Notification, SaveButton, useNotify, useRecordContext, useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";

// Material UI components
import { Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useRefresh } from 'react-admin';

// Components
import { Button } from '@mui/material';
import globalUseStyles from "../../styles/globalCustomStyles";
import BackButton from "../Buttons/BackButton";
import BottomButton from "../Buttons/BottomButton";
import CardWrapper from "../aegeon/cards/CardWrapper";
import useMutationProvider from '../aegeon/mutation/useMutationProvider';
import UserCardGeneralInformation from "./Cards/UserCardGeneralInformation";
import UserCardPerimeter from "./Cards/UserCardPerimeter";


const UsersShowDetails = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const create = props.create;

  const refresh = useRefresh();

  const record = useRecordContext();

  const [password, setPassword] = useState("");
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [roleSelected, setRoleSelected] = useState(record?.role);

  const setSelectedRole = (value) => {
    if (value !== roleSelected) {
      setRoleSelected(value);
    }
  }

  const handleClose = () => {
    navigator.clipboard.writeText(password);
    setOpenPasswordDialog(false);
    navigate("/users")
  };

  const onSuccessCallback = (data, variables, context) => {
    setPassword(data?.data?.password?.password);
    notify(
      'users.notifications.updated_success',
      {
        type: 'success',
        messageArgs: {
          _:
            typeof data === 'string'
              ? data
              : data && data?.message
                ? data?.message
                : undefined,
        },
      }
    );
    refresh();
    if (variables.mutation_name === "createUser") {
      setOpenPasswordDialog(true);
    }
    else {
      navigate("/users")
    }
  };

  const onErrorCallback = (error, variables, context) => {
    notify(
      typeof error === 'string'
        ? error
        : typeof error === 'undefined' || !error?.message
          ? 'users.notifications.updated_error'
          : error?.message,
      {
        type: 'error',
        messageArgs: {
          _:
            typeof error === 'string'
              ? error
              : error && error?.message
                ? error?.message
                : undefined,
        },
      }
    );
  };

  const mutation = useMutationProvider("updateUser", { id: record?.id, input: { email: record?.email, role: record?.role, enabled: record?.enabled, username: record?.username, perimeter: record?.perimeter } }, onErrorCallback, onSuccessCallback);

  const handleCancelClick = () => {
    navigate("/users")
  }

  const deleteTypename = (obj) => {
    for (let key in obj) {
      if (key === "__typename") {
        delete obj[key];
      } else if (typeof obj[key] === "object") {
        deleteTypename(obj[key]);
      }
    }
  }

  const handleSaveClick = (data) => {
    setSaveDisabled(true);
    deleteTypename(data);
    if (roleSelected !== "property_tenant") {
      delete data?.timeConstraintPerimeter?.devices;
    }
    else if (data?.timeConstraintPerimeter?.devices?.length > 0 && data?.perimeter?.devices?.length > 0) {
      notify(
        'users.notifications.time_constraints_warning',
        { type: 'warning' }
      );
    }
    if (data?.perimeter?.sites?.length === 0) {
      data.perimeter.sites = null;
    }
    if (data?.perimeter?.devices?.length === 0) {
      data.perimeter.devices = null;
    }
    if (!data?.perimeter?.sites && !data?.perimeter?.devices) {
      data.perimeter = null;
    }
    if (data?.timeConstraintPerimeter?.devices?.length === 0) {
      data.timeConstraintPerimeter = null;
    }
    if (create) {
      if (!data?.perimeter?.devices) {
        data.perimeter.devices = [];
      }
      if (!data?.perimeter?.sites) {
        data.perimeter.sites = [];
      }
      mutation.mutate({ mutation_name: "createUser", input: data })
    } else {
      mutation.mutate({ mutation_name: "updateUser", id: record?.id, input: data })
    }
  }


  return (
    <div className={globalClasses.pageCard + (create ? (" " + globalClasses.pageMargin) : "")}>
      <BackButton record={record} />
      <div className={globalClasses.pageTitleContainer}>
        <div >
          <div className={globalClasses.pageTitle} >
            <Typography variant="h4">{t('users.title')}</Typography>
          </div>
          <div className={globalClasses.pageSubTitle} >
            <Typography variant="h5" >{t('users.page_subtitle')}</Typography>
          </div>
        </div>
      </div>
      <div className={globalClasses.pageContent} style={{ width: "100%" }}>
        <Form id="update-user-form" onSubmit={handleSaveClick}>
          <Grid2
            container
            spacing={4}
          >
            <Grid2 item="true" small={6} medium={6} big={6} >
              <CardWrapper resource="users" type="general_information" unclickable={true}>
                <UserCardGeneralInformation record={record} create={create} setSelectedRoleCallback={setSelectedRole} />
              </CardWrapper>
            </Grid2>
            <Grid2 item="true" small={6} medium={6} big={6}>
              <CardWrapper resource="users" type="perimeter" unclickable={true}>
                <UserCardPerimeter roleSelected={roleSelected} />
              </CardWrapper>
            </Grid2>
            <Grid2 item="true" small={6} medium={6} big={6} >
            </Grid2>
            <Grid2 item="true" small={6} medium={6} big={6} className={globalClasses.bottomButtonBar}>
              <BottomButton onClick={handleCancelClick} disabled={false} label={t('users.button.cancel')} className={globalClasses.cancelButton} />
              <SaveButton label={t('users.button.save')} form="update-user-form" className={globalClasses.customButton} disabled={saveDisabled} />
            </Grid2>
          </Grid2 >
        </Form>
        <Dialog
          open={openPasswordDialog}
          onClose={handleClose}
        >
          <DialogTitle>
            {t('users.notifications.password_title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('users.notifications.password_message')}  <b>{password}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('users.notifications.password_copy_button')}</Button>
          </DialogActions>
        </Dialog>
        <Notification />
      </div>
    </div >
  );
};

export default UsersShowDetails;