import React from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import CardWrapper from '../../aegeon/cards/CardWrapper';
import QueryInfiniteField from '../../aegeon/query/QueryInfiniteField';
import SiteEnergeticPerformance from './SiteEnergeticPerformance';

const CardEnergeticPerformance = () => {
  const t = useTranslate();
  const [subtitle, setSubtitle] = React.useState('');

  const record = useRecordContext();
  const siteId = record?.id;

  const moment = require('moment');
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  endDate.set({ date: 1 });
  endDate.subtract(1, 'days');
  const startDate = moment(endDate).subtract(1, 'years');
  const { data } = useGetList(
    'devices',
    {
      filter: {
        siteId: siteId
      },
    }
  );

  const qualityWarning = data?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'C') ? "red" : data?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'B') ? "orange" : "green"

  const tooltip_text = t('sites.energetic_performance.tooltip_text') + (["red", "orange"].includes(qualityWarning) ? t('sites.energetic_performance.bad_quality_tooltip') : '')

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitle(childData);
    }
  };

  return (
    <CardWrapper resource="sites" type="energetic_performance" custom_tooltip={tooltip_text} isRedWarning={qualityWarning === "red"} isOrangeWarning={qualityWarning === "orange"} subtitleCallBack={subtitle} >
      <QueryInfiniteField query_name="getSiteTimeseries" params={{ time: { between: [startDate, endDate] } }}>
        <SiteEnergeticPerformance setSubtitleCallback={handleSetSubtitleCallback} />
      </QueryInfiniteField>
    </CardWrapper>
  )
};

export default CardEnergeticPerformance;

