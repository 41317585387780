import React from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { useTranslate, AutocompleteArrayInput, useGetList, DateTimeInput, useRecordContext, AddItemButton, DateInput } from 'react-admin';
import { ArrayInput, SimpleFormIterator, AutocompleteInput, useInput } from 'react-admin';
import { required } from 'react-admin';
import { Typography } from '@mui/material';

const UseCardPerimeter = ({roleSelected}) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const { data: sites } = useGetList(
    'sites'
  );

  const { data: devices } = useGetList(
    'devices'
  );

  const isAdmin = roleSelected === 'admin';
  const timeConstraintAllowedRoles = ["property_tenant"] ; 
  const isRoleTimeConstraintAllowed =  timeConstraintAllowedRoles.includes(roleSelected); 
  
  // TODO : here exclude the inaccessibles sites and devices ?
  const sitesChoices = sites?.map(site => ({id: site.id, name: site.airtable?.code + " - " + site.name})).sort((a, b) => a?.name?.localeCompare(b.name));
  const devicesChoices = devices?.map(device => ({id: device.id, name: device.name})).sort((a, b) => a?.name?.localeCompare(b.name));

  // convert date to date time with paris timezone
  const convertDateToDateTime = (date) => {
    if (!date) {
      return date;
    }
    let timezone = 'Europe/Paris';
    let dateWithTimezone = new Date(date);
    dateWithTimezone.setHours(0, 0, 0, 0);
    dateWithTimezone = new Date(dateWithTimezone.toLocaleString('en-US', { timeZone: timezone }))
    return dateWithTimezone;
  }

  const DevicesWithTimeConstraintsComponent = () => {
    return (
      <div className={globalClasses.timeConstraintPerimeter}>
        <Typography variant="h9" >
            {t('users.perimeter.devicesWithTimeConstraintsSubtitle')}
        </Typography>
        <ArrayInput source="timeConstraintPerimeter.devices" label={t('users.perimeter.devicesWithTimeConstraints')} defaultValue={[]}>
            <SimpleFormIterator disableReordering addButton={<AddItemButton label={t('users.perimeter.addDevice')}/>} >
                <AutocompleteInput source="id" label={t('users.perimeter.device')} variant='outlined' choices={devicesChoices} fullWidth/>
                <ArrayInput source="periods" label={t('users.perimeter.periods')} defaultValue={[{startDate: null, endDate: null}]}>
                  <SimpleFormIterator inline addButton={<AddItemButton label={t('users.perimeter.addPeriod')}/>} >
                    <DateInput source="startDate" variant='outlined' label={t('users.perimeter.startDate')} parse={v => convertDateToDateTime(v)} validate={required()}/>
                    <DateInput source="endDate" variant='outlined' label={t('users.perimeter.endDate')} parse={v => convertDateToDateTime(v)} />
                  </SimpleFormIterator>
                </ArrayInput>
            </SimpleFormIterator>
        </ArrayInput>
      </div>
    )
  }

  return (
    <div className={globalClasses.UserGeneralInformation + " " + globalClasses.customChip} >
      <AutocompleteArrayInput label={t('users.perimeter.sites')} optionText="name" variant="outlined" source="perimeter.sites" choices={sitesChoices} disabled={isAdmin} noOptionsText={t("resources.global.no_options")} />
      <AutocompleteArrayInput label={t('users.perimeter.devices')} optionText="name" variant="outlined" source="perimeter.devices" choices={devicesChoices} disabled={isAdmin} noOptionsText={t("resources.global.no_options")}/>
      { isRoleTimeConstraintAllowed &&
      <DevicesWithTimeConstraintsComponent />
      }
    </div>
  )
};

export default UseCardPerimeter;

