import { default as React, useEffect, useState } from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ChartMonotone from '../../aegeon/charts/ChartMonotone';
import QueryField from "../../aegeon/query/QueryField";
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";

const MonotoneCardDetails = (props) => {
  const globalClasses = globalUseStyles();

  const moment = require('moment');
  const startDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(7, 'days');
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter = { startDate: startDate, endDate: endDate };

  const [data, setData] = useState(null);
  const setDataCallback = (childData) => {
    if (childData && !data) {
      setData(childData);
    }
  };

  useEffect(() => {
    props.setDataCallback(data);
  }, [data, props]);

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      props.setSubtitleCallback && props.setSubtitleCallback(childData)
    }
  };

  return (
    <TimeseriesField source="active_power_monotone" filter={filter}>
      <QueryField query_name='getDeviceContract' >
        <div style={{ width: "100%", height: "550px" }} >
          <ChartMonotone isDetailedCard={true} setSubtitleCallback={handleSetSubtitleCallback} setMonotoneDataCallback={setDataCallback} />
        </div>
      </QueryField>
    </TimeseriesField>
  )
};

export default MonotoneCardDetails;

