// Create React component named AnalysisCardHistoric

import { Accordion, AccordionDetails, AccordionSummary, Card, Typography } from "@mui/material";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';
import { useEffect, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import AnalyseLoadingAnimation from '../../../assets/AnalyseLoadingAnimation';
import globalUseStyles from '../../../styles/globalCustomStyles';

const AnalysisCardTextDetails = (props) => {

  const t = useTranslate();
  const globalClasses = globalUseStyles();

  const record = useRecordContext(props);
  const {
    analysisType,
    analysisClass,
    loadingAnimation,
    customAnalysisState,
    currentDate,
    isLoading,
    title,
    isIdle,
    setDataCallback
  } = props;

  const [analysisState, setAnalysisState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (record.analysis === undefined || record.analysis === null) {
      let rawObject = markdownToDraft(t("resources.analyse.no_analysis"));
      const analysisContentState = convertFromRaw(rawObject);
      setAnalysisState(EditorState.createWithContent(analysisContentState));
    } else {
      if (record.analysis[analysisType.toLowerCase()] === undefined) {
        let rawObject = markdownToDraft(t("resources.analyse.no_analysis_type"));
        const analysisContentState = convertFromRaw(rawObject);
        setAnalysisState(EditorState.createWithContent(analysisContentState));
      }
      else {
        let rawObject = markdownToDraft(record.analysis[analysisType.toLowerCase()].text);
        const analysisContentState = convertFromRaw(rawObject);
        setAnalysisState(EditorState.createWithContent(analysisContentState));
      }
    }
  }, [analysisType, record.analysis])

  const convertDate = (date) => {
    return new Date(date).toLocaleDateString(localStorage.getItem("language"), { year: 'numeric', month: 'long', day: 'numeric' })
  }

  const last_date_string = convertDate(record.analysis?.[analysisType.toLowerCase()]?.created_at)
  const date = currentDate ? convertDate(new Date()) : last_date_string

  const isData = record.analysis?.[analysisType.toLowerCase()] !== undefined && record.analysis?.[analysisType.toLowerCase()] !== null
  const title_text = (isData || !isIdle) ? title + date : t("resources.analyse.no_analysis")

  const last_analysis = [{ "created_at": record.analysis?.[analysisType.toLowerCase()]?.created_at, "text": record.analysis?.[analysisType.toLowerCase()]?.text }]
  const historic = [{ "created_at": new Date(), "text": "test 1" }, { "created_at": new Date(), "text": "test 2" }]

  setDataCallback && setDataCallback(last_analysis)

  return (
    <div >
      <Card className={globalClasses.DetailCard + ' ' + globalClasses.DetailCardReduced}>
        <Accordion expanded={true} disableGutters={true} className={globalClasses.analysisAccordion + ' ' + (loadingAnimation ? globalClasses.analysisAccordionLoading : '')}>
          <AccordionSummary
            expandIcon={<></>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography display="block" variant="h8">
              {title_text}
            </Typography>
          </AccordionSummary>
          {loadingAnimation && <AnalyseLoadingAnimation />}
          {!isLoading &&
            <AccordionDetails>
              <Editor editorState={customAnalysisState ? customAnalysisState : analysisState} readOnly={true} />
            </AccordionDetails>
          }
        </Accordion>
      </Card>
      {/* <AnalysisCardHistoricDetails historic={historic} /> */}
    </div>
  )
};





export default AnalysisCardTextDetails;
