import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import CustomTable from '../../../components/Table/CustomTable';
import CustomTooltip from '../../../components/aegeon/CustomTooltip';
import ChartLoading from '../../../components/aegeon/charts/ChartLoading';
import { useQueryContext } from '../../../components/aegeon/query/useQueryContext';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { convert_array_to_string, formatDateString } from '../../aegeon/helper';

const DeviceGeneralInformation = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();

  const {
    data,
    isLoading,
  } = useQueryContext(props);

  const record = useRecordContext();

  if (isLoading === true) {
    return (
      <ChartLoading />
    );
  }

  const usage_tooltip = (() => {
    switch (data?.destination) {
      case "COMMUNES":
        return 'devices.general_information.usage_no_data_commune';
      case "PRIVATIVES VACANTES ET COMMUNES":
        return 'devices.general_information.usage_no_data_commune';
      case "PRIVATIVES":
        return 'devices.general_information.usage_no_data_priv';
      case "PRIVATIVES VACANTES":
        return 'devices.general_information.usage_no_data_priv';
      case "TIERCES":
        return 'devices.general_information.usage_no_data_tierce';
      default:
        return 'devices.general_information.usage_no_data';
    };
  })();

  const distributor = record?.airtable?.distributor_name

  const automatic_upload_tooltip = (() => {
    if (record?.airtable?.automatic_upload !== "Automatique" && (distributor === "ENEDIS" || distributor === "GRDF")) {
      return 'devices.general_information.automatic_upload_tooltip_no_data_missing_mandate';
    }
    else if (record?.airtable?.automatic_upload !== "Automatique" && !record?.airtable?.customer_space_available) {
      return 'devices.general_information.automatic_upload_tooltip_no_data_missing_customer_space';
    }
    else return null
  })();

  const need_mandate_or_error_tooltip = (() => {
    if (record?.airtable?.automatic_upload === "Automatique") {
      return 'devices.general_information.error_tooltip';
    }
    else {
      return 'devices.general_information.need_mandate_tooltip';
    }
  })();

  const fluid = record?.airtable?.fluid_type ? t('devices.general_information.fluids.' + record?.airtable?.fluid_type) : null;
  const is_not_automatic = record?.airtable?.automatic_upload !== "Automatique";

  const SiteWithLinkComponent = (name, id) => {
    return (
      <>
        <span>{name}</span>
        <CustomTooltip title={t('devices.general_information.site_code_link_tooltip')} icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} className={globalClasses.tableCellIconMultiple} />} clickable={true} path={"/sites/" + id + "/show"} />
      </>
    )
  }

  const sitesInfos = record?.airtable?.sites

  const sites_codes = sitesInfos.length === 1 ? sitesInfos[0]?.code : (
    <div>
      {sitesInfos.map((site) => SiteWithLinkComponent(site?.code, site?.id))}
    </div>
  )

  const rows = [
    { name: t('devices.general_information.id'), value: record?.name, tooltip: 'devices.general_information.id_no_data' },
    { name: t('devices.general_information.fluid'), value: fluid, tooltip: 'devices.general_information.fluid_no_data' },
    {
      name: t('devices.general_information.site_code'), value: sites_codes,
      path: sitesInfos.length === 1 ? "/sites/" + record?.siteId + "/show" : null,
      tooltip: 'devices.general_information.site_code_no_data', tooltip_link: 'devices.general_information.site_code_link_tooltip'
    },
    { name: t('devices.general_information.data_quality'), value: record?.fusionedEnergyQuality, tooltip: 'devices.general_information.data_quality_no_data' },
    { name: t('devices.general_information.usage'), value: convert_array_to_string(record?.airtable?.usage), tooltip: usage_tooltip },
    { name: t('devices.general_information.destination'), value: record?.airtable?.destination, tooltip: 'devices.general_information.destination_no_data' },
    { name: t('devices.general_information.automatic_upload'), value: record?.airtable?.automatic_upload, tooltip: automatic_upload_tooltip, tooltip_with_value: true },
  ]

  if (distributor === "ENEDIS") {
    rows.splice(2, 0, { name: t('devices.general_information.contract_status'), value: data?.donneesGenerales?.etatContractuel?.libelle, tooltip: 'devices.general_information.contract_status_no_data' })
    rows.push({ name: t('devices.general_information.segment'), value: data?.donneesGenerales?.segment?.libelle, tooltip: need_mandate_or_error_tooltip })
    rows.push({ name: t('devices.general_information.forfait_change_at'), value: formatDateString(data?.donneesGenerales?.dateDerniereModificationFormuleTarifaireAcheminement), tooltip: '', hide_if_null: true })
  }
  else if (distributor === "GRDF") {
    rows.splice(2, 0, { name: t('devices.general_information.grdf_status'), value: is_not_automatic ? null : data?.permissions?.etat_droit_acces, tooltip: need_mandate_or_error_tooltip })
    rows.push({ name: t('devices.general_information.segment'), value: is_not_automatic ? null : data?.contracts?.tarif_acheminement, tooltip: need_mandate_or_error_tooltip })
  }

  // The rows tooltips are translated in the CustomTable component
  return (<CustomTable rows={rows} leftSizeBigger={true} defaultTooltip={"devices.general_information.no_data_tooltip"} />)
}

export default DeviceGeneralInformation
