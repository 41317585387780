import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { aggregateDiff, aggregateTimeseriesByField, calculateTotalColumns, convertTimeseriesObjectToList } from '../../../helpers/helper';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CustomLargeTable from '../../Table/CustomLargeTable';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import { useTimeseriesContext } from '../../aegeon/timeseries/useTimeseriesContext';

const TableSubDevicesComponent = (props) => {
  const moment = require('moment');
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const [rows, setRows] = useState(null);
  const setDataSourcesCallback = props.setDataSourcesCallback;
  const [isAggregateByContractHolder, setIsAggregateByContractHolder] = useState(false);

  const {
    data,
    isQueryLoading,
    hasNextPage,
  } = useQueryContext(props);

  const {
    timeseries,
    isTimeseriesLoading,
  } = useTimeseriesContext(props);

  const deviceId = props.record?.id;

  const { data: subdevicesList } = useGetList(
    'subdevices',
    {
      filter: {
        deviceId: deviceId,
      },
    }
  );


  const aggregateByContractHolder = (data) => {
    let lastMonths = [];
    for (let i = 11; i >= 0; i--) {
      lastMonths.push(moment().subtract(i, 'months').format("YYYY-MM"));
    }

    let months_headers = lastMonths.map((month) => {
      return t("months." + (parseInt(month.split("-")[1]) - 1)) + " " + month.split("-")[0];
    });


    const rows = [
      {
        values: [
          t('devices.general_information.subdevices.contract_holder'),
          t('devices.general_information.subdevices.start_date_min'),
          t('devices.general_information.subdevices.end_date_min'),
          t('devices.general_information.subdevices.unit'),
          ...months_headers,
          t('devices.general_information.subdevices.total')
        ]
      }
    ];

    data.items.forEach(item => {
      let subdevice = subdevices?.find(subdevice => subdevice.name === item.id)
      if (subdevice) {
        item.name = subdevice.contract_holder ? subdevice.contract_holder : " "
      }
    });

    let agg_data = aggregateTimeseriesByField(data.items, "name");
    let agg_timeseries = convertTimeseriesObjectToList(agg_data.result);

    agg_timeseries.forEach((subdevice) => {
      let timeseries_values = [];
      let start_date_min = "";
      let end_date_min = "";

      lastMonths.forEach((month) => {
        let timeserie = subdevice?.timeseries?.find((timeserie) => moment(timeserie.time).format("YYYY-MM") === month);
        // Sort by date

        subdevice?.timeseries?.sort((a, b) => new Date(a.time) - new Date(b.time));

        // transform start_date_min to local format %d/%m/%Y
        start_date_min = subdevice?.timeseries[0]?.time;
        start_date_min = moment(start_date_min).format("DD/MM/YYYY");

        // transform end_date_min to local format %d/%m/%Y
        end_date_min = subdevice?.timeseries[subdevice?.timeseries.length - 1]?.time;
        end_date_min = moment(end_date_min).format("DD/MM/YYYY");

        timeseries_values.push(timeserie?.value ? Math.round(timeserie?.value) : " ");
      });

      // Add total to the end of the row
      let total = timeseries_values
        .filter((value) => Number.isFinite(value))
        .reduce((a, b) => a + b, 0);
      timeseries_values.push(total);

      rows.push({
        values: [
          subdevice?.name,
          start_date_min,
          end_date_min,
          "kWh PCS",
          ...timeseries_values
        ]
      });
    });

    // Calculate total columns
    const ignoredColumns = [
      t('devices.general_information.subdevices.contract_holder'),
      t('devices.general_information.subdevices.start_date_min'),
      t('devices.general_information.subdevices.end_date_min'),
      t('devices.general_information.subdevices.unit'),
    ]
    const totalColumns = calculateTotalColumns(lastMonths, rows, ignoredColumns);

    rows.push({
      values: [
        t('devices.general_information.subdevices.total'),
        "",
        "",
        "kWh PCS",
        ...totalColumns.map((element) => element.value),
      ]
    });

    return rows;
  }

  const aggregateByMonth = (data) => {
    let lastMonths = [];
    for (let i = 11; i >= 0; i--) {
      lastMonths.push(moment().subtract(i, 'months').format("YYYY-MM"));
    }

    let months_headers = lastMonths.map((month) => {
      return t("months." + (parseInt(month.split("-")[1]) - 1)) + " " + month.split("-")[0];
    });

    const rows = [{
      values: [
        t('devices.general_information.subdevices.name'),
        t('devices.general_information.subdevices.description'),
        t('devices.general_information.subdevices.lessor'),
        t('devices.general_information.subdevices.contract_holder'),
        t('devices.general_information.subdevices.start_date_min'),
        t('devices.general_information.subdevices.end_date_min'),
        t('devices.general_information.subdevices.unit'),
        ...months_headers,
        t('devices.general_information.subdevices.total')
      ]
    }]

    data?.items?.forEach((subdevice) => {
      let timeseries_values = [];
      let start_date_min = "";
      let end_date_min = "";

      lastMonths.forEach((month) => {
        let timeserie = subdevice?.timeseries?.find((timeserie) => moment(timeserie.time).format("YYYY-MM") === month);
        timeseries_values.push(timeserie?.value ? Math.round(timeserie?.value) : " ");
      });

      let subdevice_infos = subdevices?.find((sub) => sub.name === subdevice?.id);
      let description = subdevice_infos?.description ? subdevice_infos?.description : " "
      let lessor = subdevice_infos?.lessor ? subdevice_infos?.lessor : " "
      let contract_holder = subdevice_infos?.contract_holder ? subdevice_infos?.contract_holder : " "

      subdevice?.timeseries?.sort((a, b) => new Date(a.time) - new Date(b.time));

      // transform start_date_min to local format %d/%m/%Y
      start_date_min = subdevice?.timeseries[0]?.time;
      start_date_min = moment(start_date_min).format("MM/YYYY");

      // transform end_date_min to local format %d/%m/%Y
      end_date_min = subdevice?.timeseries[subdevice?.timeseries.length - 1]?.time;
      end_date_min = moment(end_date_min).format("MM/YYYY");

      // Add total to the end of the row ignore " " character in timeseries_values

      let total = timeseries_values
        .filter(value => Number.isFinite(value))
        .reduce((a, b) => a + b, 0);
      timeseries_values.push(total);

      rows.push({
        values: [subdevice?.id,
          description,
          lessor,
          contract_holder,
          start_date_min,
          end_date_min,
          "kWh PCS",
        ...timeseries_values]
      })
    });

    // Calculate total columns
    const ignoredColumns = [t('devices.general_information.subdevices.name'),
    t('devices.general_information.subdevices.description'),
    t('devices.general_information.subdevices.lessor'),
    t('devices.general_information.subdevices.contract_holder'),
    t('devices.general_information.subdevices.start_date_min'),
    t('devices.general_information.subdevices.end_date_min'),
    t('devices.general_information.subdevices.unit')];
    const totalColumns = calculateTotalColumns(lastMonths, rows, ignoredColumns);

    // Push final row with total columns
    rows.push({
      values: [
        t('devices.general_information.subdevices.total'),
        "",
        "",
        "",
        "",
        "",
        "kWh PCS",
        ...totalColumns.map((element) => element.value),
      ]
    });

    return rows;
  }

  const { data: subdevices } = useGetList(
    'subdevices',
    {
      filter: {
        deviceId: deviceId,
      },
    }
  );

  useEffect(() => {
    if (data && data.items && !isQueryLoading && !isTimeseriesLoading && !hasNextPage && subdevices && timeseries && timeseries.length > 0) {
      const aggData = { "items": aggregateDiff(data.items, timeseries) };
      aggData.items[aggData.items.length - 1].name = t(`devices.general_information.subdevices.${aggData.items[aggData.items.length - 1].name}`);
      aggData.items[aggData.items.length - 1].id = t(`devices.general_information.subdevices.${aggData.items[aggData.items.length - 1].id}`);
      let aggRows = [];
      if (isAggregateByContractHolder) {
        aggRows = aggregateByContractHolder(aggData);
      }
      else {
        aggRows = aggregateByMonth(aggData);
      }
      setRows(aggRows);
      if (setDataSourcesCallback && aggData?.items && aggData?.items.length > 0) {
        setDataSourcesCallback(convertData(rows), "subdevice");
      }
    }
  }, [isQueryLoading, isTimeseriesLoading, hasNextPage, data, timeseries, subdevices, isAggregateByContractHolder]);

  const convertData = (rows) => {
    if (rows) {
      let data = []
      rows.forEach((row) => {
        data.push(row.values)
      }
      )
      return data
    }
    return []
  }

  const switchAggregation = (event) => {
    setIsAggregateByContractHolder(event.target.checked);
  };

  const convertRows = (rows) => {
    let convertedRows = [];
    rows = rows.slice(1);
    rows.forEach((row, index) => {
      let isLastRow = index === rows.length - 1;
      let isPreviousLastRow = index === rows.length - 2;
      let convertedRow = {
        name: row.values[0],
        values: row.values.slice(1),
        name_tooltip: isPreviousLastRow ? "devices.general_information.subdevices.diff_tooltip" :
          isLastRow ? "devices.general_information.subdevices.total_tooltip" :
            null
      }
      convertedRows.push(convertedRow);
    }
    )
    return convertedRows;
  }

  return (
    !isQueryLoading && data && data.items && data.items.length > 0 ?
      <Card className={globalClasses.DetailCard}>
        <Grid2 container direction="row" justifyContent="center" alignItems="center"
          spacing={5}
          style={{ padding: '0 !important' }}
        >
          <Grid2 item="true" small={12} medium={12} big={12}>
            <div className={globalClasses.DetailCardTableTitle}>
              <Typography variant="h8">{t("devices.general_information.subdevices.title")}</Typography>
            </div>
            <div className={globalClasses.DetailCardTableSubtitle}>
              {t("devices.general_information.subdevices.subtitle")}
            </div>
            <div className={globalClasses.SubDevicesAggregation}>
              <Typography variant="h12">
                {t('devices.subdevices.charts.aggregation_contract_holder')}
              </Typography>
              <Switch onChange={switchAggregation} />
            </div>
            <div style={{ minHeight: "100px", border: "1px solid #A6ACAF60" }}>
              {
                !rows ? <ChartLoading /> :
                  rows && rows.length > 0 ?
                    <CustomLargeTable
                      head={rows[0].values}
                      rows={convertRows(rows)}
                      disableMissingDataTooltip={true}
                    />
                    : null
              }
            </div>
          </Grid2>
        </Grid2>
      </Card>
      :
      subdevicesList && subdevicesList.length > 0 ?
        <Card className={globalClasses.DetailCard}>
          <ChartLoading />
        </Card>
        : null
  );
}

export default TableSubDevicesComponent;