import React from 'react';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CardWrapper from '../../aegeon/cards/CardWrapper';

import QueryField from "../../aegeon/query/QueryField";
import DeviceGeneralInformation from "./DeviceGeneralInformation";

const CardGeneralInformation = () => {
  const globalClasses = globalUseStyles();
  return (
    <CardWrapper resource="devices" type="general_information" >
      <QueryField query_name='getDeviceContract' >
        <div className={globalClasses.generalInformation}>
          <DeviceGeneralInformation />
        </div>
      </QueryField>
    </CardWrapper>
  )
};

export default CardGeneralInformation;

