import React, { useEffect } from 'react';
import ChartTable from '../../aegeon/charts/ChartTable';
import { getTableSeries } from '../../aegeon/compute_table';
import { useTimeseriesContext } from '../../aegeon/timeseries/useTimeseriesContext';

const ChartDeviceTable = (props) => {
  const {
    timeseries,
    isLoading,
    unit,
  } = useTimeseriesContext(props);

  const isLoadingCallBack = props.isLoadingCallBack;
  const source = props.source;

  useEffect(() => {
    isLoadingCallBack && isLoadingCallBack(isLoading)
  }, [isLoading, isLoadingCallBack]);


  const groupByTrimester = props.groupBy === "trimester"

  let groupedSerie = timeseries && timeseries.length > 0 ? getTableSeries(timeseries, groupByTrimester) : {}

  return (
    <ChartTable
      source={source}
      isLoading={isLoading}
      timeseries={timeseries}
      isLocked={props.isLocked}
      groupedSerie={groupedSerie}
      unit={props.unit ? props.unit : unit}
      className={props.className}
      setSubtitleCallback={props.setSubtitleCallback}
      groupBy={props.groupBy}
      setDataCallback={props.setDataCallback}
      isDetailed={props.isDetailed}
    />
  )
}

export default ChartDeviceTable