import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import ExportButton from '../../Buttons/ExportButton';
import AnalysisCardDetails from '../../aegeon/cards/AnalysisCardDetails';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';

const AnalysisTextDetails = (props) => {

  const t = useTranslate();
  const [data, setData] = useState();
  const [disabled, setDisabled] = useState(true);
  const title = t('devices.analyse.title');

  const setDataCallback = (childData) => {
    if (childData && !data) {
      setData(childData)
    }
  }

  useEffect(() => {
    if (data !== undefined) {
      setDisabled(false)
    }
  }, [data])

  const handleExport = (name) => {
    var sheets_data = [];
    if (data !== null) {
      data.map((item, index) => {
        const date = new Date(item.created_at).toLocaleDateString(localStorage.getItem("language"), { year: 'numeric', month: 'long', day: 'numeric' })
        sheets_data.push({ sheetName: index + " - " + date, data: [["Date de l'analyse", date], ["Résultat de l'analyse", item.text]] });
      }
      )
      export_row_to_excel(sheets_data, `${t('resources.analyse.export_name')}${name}.xlsx`);
    };
  };

  return (
    <DetailsShow title={title} resource="devices" tooltip={<ExportButton onClick={handleExport} disabled={disabled} />} >
      <AnalysisCardDetails analysisClass="DEVICE" analysisType="METER_COMMISSIONING" setDataCallback={setDataCallback} />
    </DetailsShow>
  )
};

export default AnalysisTextDetails;

