import globalUseStyles from "../../../styles/globalCustomStyles";

import { Card } from "@mui/material";
import React from 'react';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

import CardHeader from "@mui/material/CardHeader";
import { useLocation } from 'react-router-dom';
import ExclamationTooltipIcon from "../../../assets/ExclamationTooltipIcon";
import InfoTooltipIcon from "../../../assets/InfoTooltipIcon";
import { theme } from "../../../theme";
import CustomTooltip from "../CustomTooltip";


const CardWrapper = (props) => {
    const t = useTranslate();
    const globalClasses = globalUseStyles();
    const type = props.type;
    const resource = props.resource;
    const title = t(`${resource}.${type}.title`);
    const subtitle = t(`${resource}.${type}.subtitle`);
    const children = props.children;
    const location = useLocation();
    const subtitleCallBack = props.subtitleCallBack;
    const tooltip_text = props.custom_tooltip ? props.custom_tooltip : t(`${resource}.${type}.tooltip_text`);
    const icon = props.isRedWarning ? <ExclamationTooltipIcon color={theme.palette.aegeon.danger} /> : props.isOrangeWarning ? <ExclamationTooltipIcon color={theme.palette.aegeon.warning} /> : <InfoTooltipIcon />;

    const convertDatesInColor = (text) => {
        const regex = /(\d{2}\/\d{2}\/\d{4})|(\d{2}\/\d{2})/g;
        const dates = text.match(regex);
        if (dates) {
            return text.split(regex).map((item, index) => {
                if (dates.includes(item)) {
                    return <span key={index} style={{ color: "#71b9a1" }}>{item}</span>;
                }
                return item;
            });
        } else {
            return text;
        }
    }


    const CardComponent = (props) => (
        <>
            <Card className={`${globalClasses.Card} ${props.className}`} >
                <div className={globalClasses.cardTitle}>
                    <CardHeader
                        title={title}
                        className={globalClasses.CardHeader}
                    />
                    {tooltip_text && <CustomTooltip title={tooltip_text} icon={icon} />}
                </div>
                <div className={globalClasses.cardSubtitle}>
                    {subtitleCallBack ? convertDatesInColor(subtitleCallBack) : subtitle}
                </div>
                <div style={{ width: "100%", height: "80%" }}>
                    {children}
                </div>
            </Card>
        </>
    );


    return (
        props.unclickable ?
            <CardComponent className={globalClasses.unclickableCard} />
            :
            <Link to={`${location.pathname}/` + type} style={{ textDecoration: 'none' }}>
                <CardComponent className="" />
            </Link>
    )
};

export default CardWrapper;

