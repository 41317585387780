import { API, graphqlOperation } from "aws-amplify";
import { buildDataProvider } from "react-admin-amplify";

const transformFilter = (filter) => {
  let filterList = [];
  for (const field in filter) {
    const value = filter[field];
    if (typeof value === "string" || typeof value === "number") {
      if (field !== "eq" && field !== "contains") {
        filterList.push({ [field]: { beginsWith: value } });
      } else {
        filterList.push({ [field]: value });
      }
    } else if (
      typeof value === "object" &&
      Object.keys(value).length === 0 &&
      !value.beginsWith &&
      !value.eq &&
      !value.contains
    ) {
      for (const subField in value) {
        filterList.push({ [field]: transformFilter({ [subField]: value[subField] }) });
      }
    }
  }
  if (filterList.length === 0) {
    return filter;
  } else if (filterList.length === 1) {
    return filterList[0];
  } else {
    return { and: filterList };
  }
};

const transformSort = (sort) => {
  const { field, order } = sort;
  const direction = order;
  return { field, direction };
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const createQueryMap = (queries, operation) => {
  const queryMap = {};

  for (const queryName in queries) {
    if (queryName.startsWith(operation)) {
      const resourceName = queryName.replace(operation, "").toLowerCase();
      queryMap[resourceName] = queries[queryName];
    }
  }

  return queryMap;
};

const customAmplifyDataProvider = (options) => {
  const dataProvider = buildDataProvider(options);
  const listQueryMap = createQueryMap(options.queries, "list");
  const getQueryMap = createQueryMap(options.queries, "get");
  // Create query maps for update, create, and delete operations if needed

  const getResourceQueryName = (resource, operation) => {
    if (operation === "list") {
      return listQueryMap[resource];
    } else if (operation === "get") {
      return getQueryMap[resource];
    }
    // Add logic for update, create, and delete operations if needed
  };

  const customGetList = async (resource, params) => {
    const { sort, filter, meta } = params;
    // Modify the filter and sort parameters to handle custom sorting and filtering logic
    const modifiedFilter = transformFilter(filter);
    const modifiedSort = transformSort({ field: "id", order: sort.order });
    let sortFields = [];
    if (meta && meta.sortFunction && meta.sortFields) {
      if (sort.field !== "id") {
        // we sort by one column
        sortFields = [sort];
        if (meta.defaultSort) {
          meta.setDefaultSort();
        }
      } else {
        // we sort by all the fields
        sortFields = meta.sortFields;
        if (!meta.defaultSort) {
          meta.setDefaultSort();
        }
      }
    }

    // Get the query for the resource
    const query = getResourceQueryName(resource, "list");

    // Create queryName with the operation and resource starting with a capital letter
    const queryName = `list${capitalizeFirstLetter(resource)}`;

    // Replace listYourResourceName with the fetched queryName
    const result = await API.graphql(
      graphqlOperation(query, {
        filter: modifiedFilter,
        sort: modifiedSort,
      })
    );

    let items = result.data[queryName].items.map((item) => ({
      ...item,
      id: item.id,
    }));

    let previousNextToken = result.data[queryName].nextToken;
    let nextToken = result.data[queryName].nextToken;

    while (nextToken) {
      const result_nextToken = await API.graphql(
        graphqlOperation(query, {
          filter: modifiedFilter,
          sort: modifiedSort,
          nextToken: nextToken,
        })
      );

      previousNextToken = nextToken;
      nextToken = result_nextToken.data[queryName].nextToken;

      // Break if the nextToken is the same as the previous one beacuse it means that there is a problem with backend
      // and it is returning the same nextToken
      if (nextToken === previousNextToken) break;

      // Append to items arrays
      items = items.concat(
        result_nextToken.data[queryName].items.map((item) => ({
          ...item,
          id: item.id,
        }))
      );
    }

    meta && meta.sortFunction && meta.sortFields && meta.sortFunction(items, sortFields);

    // Transform the result into the format expected by react-admin
    return {
      // TODO : Filter from backend instead of it
      data: items
        .filter((item) => !item?.airtable?.fluid_type || !item?.airtable?.fluid_type?.includes("ERREUR"))
        .map((item) => ({
          // data: items.map((item) => ({
          ...item,
          id: item.id,
        })),
      total: result.data[queryName].totalCount,
    };
  };

  const customGetOne = async (resource, params) => {
    const { id } = params;
    const meta_params = { ...params?.meta };
    let single_resource = resource.slice(0, -1);

    let result;
    const query = getResourceQueryName([single_resource], "get");

    const queryName = `get${capitalizeFirstLetter(single_resource)}`;

    result = await API.graphql(graphqlOperation(query, { id, ...meta_params }));
    return {
      data: {
        ...result.data[queryName],
        id: result.data[queryName].id,
      },
    };
  };

  // Wrap the original dataProvider with the custom getList function
  return {
    ...dataProvider,
    getList: (resource, params) => customGetList(resource, params),
    getOne: (resource, params) => customGetOne(resource, params),
    // Override other dataProvider methods if needed
  };
};

export default customAmplifyDataProvider;
