import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { convert_data_to_lines } from '../helper';
import { useQueryContext } from '../query/useQueryContext';
import { useTimeseriesContext } from '../timeseries/useTimeseriesContext';
import ChartLoading from './ChartLoading';
import ChartNoData from './ChartNoData';

var formatTooltipLine = function (color, text) {
  return "<span style='display:inline-block;width:10px;height:10px;border-radius:50%;background-color:" + color + ";margin-right:5px;'></span><span><b>" + text + "<b></span>"
}


function generateMonotoneInKW(data) {
  let monotoneData = data.sort(function (a, b) {
    return new Date(b.time) - new Date(a.time);
  });

  // keep only data from the last execution (in the hour before the last time)
  var mostRecentDate = new Date(monotoneData[0].time);
  monotoneData = monotoneData.filter(function (element) {
    var date = new Date(element.time);
    return mostRecentDate - date < 3600000;
  });

  monotoneData = monotoneData.sort(function (a, b) {
    return b.value - a.value;
  });

  let total_minutes = monotoneData[monotoneData.length - 1].monotone_cumulative_time_in_min

  monotoneData = monotoneData.map((element) => {
    return { value: Math.round(element.value) / 1000, monotone_cumulative_time_in_min: Math.round(element.monotone_cumulative_time_in_min / total_minutes * 100 * 1000) / 1000 }
  })

  return monotoneData;
}

const ChartMonotone = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();

  const {
    name,
    timeseries,
    unit,
    isLoading,
  } = useTimeseriesContext(props);

  const {
    data,
  } = useQueryContext(props);

  const {
    hasDataZoom,
    setMonotoneDataCallback,
    isDetailedCard,
    setSubtitleCallback
  } = props;

  let dataZoom = [];

  if (hasDataZoom) {
    dataZoom = [
      {
        type: 'slider',
        start: 0,
        end: 100
      }
    ]
  }

  const lastData = timeseries ? timeseries[timeseries?.length - 1] : null;
  const monotoneStartDate = lastData ? new Date(lastData.monotone_start_date) : null;
  const monotoneEndDate = lastData ? new Date(lastData.monotone_end_date) : null;

  const isMonotone = monotoneStartDate && monotoneEndDate ? monotoneEndDate - monotoneStartDate > 2592000000 : false;

  let monotone_data = timeseries && timeseries.length > 0 && isMonotone ? generateMonotoneInKW(timeseries) : []
  let monotoneValueUnit = unit === 'W' ? 'kW' : ''

  const subtitle_text = `${t('devices.monotone.subtitle')}` + (monotoneStartDate && monotoneEndDate ? `${t('devices.monotone.subtitle_2')} ${monotoneStartDate.toLocaleDateString(localStorage.getItem("language"))} au ${monotoneEndDate.toLocaleDateString(localStorage.getItem("language"))} ?` : '?');

  useEffect(() => {
    if (setSubtitleCallback) {
      setSubtitleCallback(subtitle_text)
    }
  }, [isMonotone, setSubtitleCallback, subtitle_text])

  const max_subscribed_power = data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.valeur
  const max_subscribed_power_unit = data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.unite ? data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.unite : ''

  const series = [
    {
      name: name,
      type: 'line',
      showSymbol: false,
      data: monotone_data.map(elem => [elem.monotone_cumulative_time_in_min, elem.value]),
      itemStyle: {
        color: '#5da4d9'
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0.5,
            color: '#5da4d9'
          },
          {
            offset: 1,
            color: '#5da4d950'
          }
        ])
      },
    },
    {
      name: 'Puissance souscrite',
      type: 'line',
      showSymbol: false,
      data: max_subscribed_power ? [[0, max_subscribed_power], [100, max_subscribed_power]] : [],
      itemStyle: {
        color: '#4ecca2'
      },
      lineStyle: {
        type: 'dashed'
      },
      label: {
        show: true,
        position: 'right',
        formatter: function (params) {
          return params.data[1] + ' ' + max_subscribed_power_unit;
        }
      },
    }

  ];

  const option = {
    legend: {
      show: max_subscribed_power,
      orient: 'vertical',
      bottom: 10,
      left: 0,
      textStyle: {
        fontSize: 10
      },
      selectedMode: isDetailedCard,
      formatter: function (name) {
        if (name === 'Puissance souscrite') {
          return name + ' (' + max_subscribed_power + ' ' + max_subscribed_power_unit + ')';
        }
        return name;
      },
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 10,
      tooltip: {
        show: isDetailedCard,
        formatter: function (name) {
          return "Cliquez pour afficher/masquer la puissance souscrite";
        },
        textStyle: {
          fontSize: 10
        },
        position: [0, '50%']
      }
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        return formatTooltipLine(params[0].color, params[0].value[1] + monotoneValueUnit) + " : " + params[0].value[0] + ' %';

      }
    },
    grid: {
      top: '5%',
      left: '8%',
      right: '3%',
      bottom: '20%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      min: 0,
      max: 100,
      axisLabel: {
        fontSize: 10,
      },
      nameTextStyle: {
        fontSize: 10,
      },
      name: "Temps cumulé (%)",
      nameLocation: 'middle',
      nameGap: 20,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        fontSize: 10,
        formatter: '{value} ',
      },
      name: "Puissance (" + monotoneValueUnit + ')',
      nameLocation: 'middle',
      nameGap: 30,
      nameTextStyle: {
        fontSize: 10,
      },
    },
    dataZoom: dataZoom,
    series: series
  };

  let converted_data = convert_data_to_lines([monotone_data.map(elem => elem.monotone_cumulative_time_in_min), monotone_data.map(elem => elem.value)], ['Temps cumulé (%)', 'Puissance (' + monotoneValueUnit + ')'])

  useEffect(() => {
    setMonotoneDataCallback && timeseries && timeseries.length > 0 && isMonotone && setMonotoneDataCallback(converted_data)
  }, [converted_data, isMonotone, setMonotoneDataCallback, timeseries])

  return (
    isLoading ? <ChartLoading /> :
      timeseries && timeseries.length > 0 && isMonotone ?
        <ReactECharts
          notMerge={true}
          option={option}
          style={{ height: '100%', width: '100%' }}
        />
        : <ChartNoData className={isDetailedCard ? globalClasses.DetailledFullNoData : ""} />
  )
}

export default ChartMonotone