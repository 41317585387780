// Libs
import React, { useState } from "react";
import { useTranslate, useNotify } from "react-admin";
import { useNavigate } from "react-router-dom";
// Material UI components
import { Typography } from "@mui/material";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { useRef } from "react";

// Custom Style
import globalUseStyles from "../../../styles/globalCustomStyles";
import BackButton from "../../Buttons/BackButton";
import BottomButton from "../../Buttons/BottomButton";
import CardWrapper from "../../aegeon/cards/CardWrapper";
import SyncSourceMain from "./SyncSourceMain";
import SyncPagePreview from "./SyncPagePreview";

const SyncPage = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const [newUsers, setNewUsers] = useState(null);
  const [preview, setPreview] = useState(false);
  const dataText = useRef(null)
  const navigate = useNavigate();
  const reader = new FileReader();
  const notify = useNotify();

  const handleCancelClick = () => {
    navigate("/users");
  };

  const joinExcelColumns = (user) => {
    Object.entries(user).forEach(([key, value]) => {
      if (key.includes("2")) {
        const newKey = key.replace("2", "");
        user[newKey] = user[newKey] + value;
        delete user[key];
      }
    });
  }

  const handleSyncClick = () => {
    const data = dataText.current;
    if (!data) {
      notify(
        "users.synchronize.error_no_data",
        { type: 'error' }
      );
      return;
    }
    let parsedData = Papa.parse(data, { header: true });
    parsedData.data.forEach(user => {
      joinExcelColumns(user);
      user["perimeter"] = {
        devices: [...new Set(user["perimeterDevicesCodes"] ? user["perimeterDevicesCodes"].split(",") : [])],
        sites: [...new Set(user["perimeterSitesCodes"] ? user["perimeterSitesCodes"].split(",") : [])],
      }
      if (user["timeConstraintPerimeterDevices"]) {
        let periods = user["timeConstraintPerimeterDevices"].split(";")
        let devices = periods.reduce((acc, period) => {
          let [deviceId, startDate, endDate] = period.split(",");
          if (!acc[deviceId]) {
            acc[deviceId] = [];
          }
          if (endDate === "null") {
            endDate = null;
          }
          if (startDate !== "null") {
            acc[deviceId].push({startDate, endDate});
          }
          return acc;
        }, {});
        user["timeConstraintPerimeter"] = {
          devices: Object.keys(devices).map(deviceId => {
            return {
              id: deviceId,
              periods: devices[deviceId]
            }
          }
          )
        }
      }
      user["enabled"] = user["enabled"] === "true";
      delete user["perimeterDevicesCodes"];
      delete user["perimeterSitesCodes"];
      delete user["perimeterDevicesCodes2"];
      delete user["timeConstraintPerimeterDevices"];
      delete user["timeConstraintPerimeterDevices2"];
    });
    parsedData.data = parsedData.data.filter(user => user.username);

    parsedData.data = parsedData.data.filter(user => ["admin", "property_manager", "property_tenant", "property_owner"].includes(user.role));
    parsedData.data = parsedData.data.filter(user => user.email && user.email.includes("@"));
    setNewUsers(parsedData.data);
    setPreview(true);
  };

  const onUploadFile = (file) => {
    if (file.length > 0) {
      let f = file[0];
      if (f.name.endsWith(".csv")) {
        reader.readAsText(f);
        reader.onload = (e) => {
          dataText.current = e.target.result;
        };
      }
      else if (f.name.endsWith(".xlsx")) {
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_csv(sheet);
          dataText.current = parsedData;
        };
        reader.readAsArrayBuffer(f);
      }
    }
  }

  return (
    <div className={globalClasses.editPageCard + " " + globalClasses.pageMargin}>
      <div className={globalClasses.fullPageCard}>
        <BackButton />
        <div className={globalClasses.pageTitleContainer}>
          <div>
            <div className={globalClasses.pageTitle}>
              <Typography variant="h4">{t("users.synchronize.title")}</Typography>
            </div>
            <div className={globalClasses.pageSubTitle}>
              <Typography variant="h5">{t("users.synchronize.page_subtitle")}</Typography>
            </div>
          </div>
        </div>

        <div className={globalClasses.pageContent + " " + globalClasses.userPageContent}>
            {preview ?
              <SyncPagePreview newUsers={newUsers} />
              :
              <>
              <CardWrapper resource="users" type="configuration" unclickable={true}>
                <SyncSourceMain onUploadFile={onUploadFile}/>
              </CardWrapper>
               <div className={globalClasses.bottomButtonBar + " "+ globalClasses.bottomButtonPage}>
               <BottomButton
                 onClick={handleCancelClick}
                 disabled={false}
                 label={t("users.button.cancel")}
                 className={globalClasses.cancelButton}
               />
               <BottomButton 
                 onClick={handleSyncClick}
                 disabled={false} 
                 label={t("users.button.synchronize")}
               />
             </div>
             </>
            }
        </div>
      </div>
    </div>
  );
};

export default SyncPage;
