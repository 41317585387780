import React, { useEffect, useState } from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import CardWrapper from "../../aegeon/cards/CardWrapper";
import ChartGauge from '../../aegeon/charts/ChartGauge';
import { formatDate, getConsumptionVariation, getPeriods, getWarningColor } from "../../aegeon/compute_evolution";
import QueryInfiniteField from "../../aegeon/query/QueryInfiniteField";
import { useQueryContext } from '../../aegeon/query/useQueryContext';

const CardEvolution = (props) => {
  const t = useTranslate();

  const [componentIsLoading, setComponentIsLoading] = useState(true);
  const [consumption, setConsumption] = useState("");
  const [lastYearDays, setLastPeriodDays] = useState("");
  const [currentYearDays, setCurrentPeriodDays] = useState("");
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");

  const record = useRecordContext();
  const siteId = record?.id;
  const { data } = useGetList(
    'devices',
    {
      filter: {
        siteId: siteId
      },
    }
  );
  const qualityWarning = data?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'C') ? "red" : data?.some((device) => device.deviceType !== "waterMeter" && device.fusionedEnergyQuality === 'B') ? "orange" : "green"

  const moment = require('moment');

  const startDate = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  endDate.set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  endDate.subtract(1, 'days');

  const EvolutionReceiver = (props) => {
    const {
      data,
      isLoading,
      hasNextPage,
    } = useQueryContext(props);

    const record = useRecordContext(props);
    const reporting_start_date = record?.airtable?.reporting_start_date

    useEffect(() => {
      if (!consumption && data && data.items && data.items.length > 0 && !hasNextPage) {
        let timeseries = data.items
        let period = getPeriods(reporting_start_date, timeseries)
        let { consumptionVariation, lastYearDays, currentYearDays } = getConsumptionVariation(timeseries, period)
        setConsumption(consumptionVariation)
        setLastPeriodDays(lastYearDays)
        setCurrentPeriodDays(currentYearDays)
        setComponentIsLoading(false)
        period.currentPeriodEndDate.setDate(period.currentPeriodEndDate.getDate() + 1)
        setCurrentStartDate(formatDate(period.currentPeriodStartDate))
        setCurrentEndDate(formatDate(period.currentPeriodEndDate))
      }
      else if (!isLoading && ((data && data.items && data.items.length === 0) || data === undefined)) {
        setComponentIsLoading(false)
      }
    }, [data, isLoading, reporting_start_date, consumption])

    return null;
  };

  let subtitle_text = consumption && currentStartDate ? t('sites.evolution.subtitle') + ' ' + (t('sites.evolution.subtitle_dates_1') + currentStartDate + t('sites.evolution.subtitle_dates_2') + currentEndDate + t('sites.evolution.subtitle_dates_3')) : t('sites.evolution.subtitle') + '?'

  let warning_color = getWarningColor(lastYearDays, currentYearDays, consumption, "day")
  let warning_text = t("sites.evolution.tooltip_text_source")
  warning_text += (warning_color === 'red' || warning_color === 'orange') ? t("sites.evolution.tooltip_warning_1") + lastYearDays + t("sites.evolution.tooltip_warning_2") + currentYearDays + t("sites.evolution.tooltip_warning_3") : (warning_color === 'green') ? t("sites.evolution.tooltip_text_1") + lastYearDays + t("sites.evolution.tooltip_text_2") : t("sites.evolution.tooltip_no_data")
  warning_text += (qualityWarning === "red") ? t("sites.evolution.subtitle_red") : (qualityWarning === "orange") ? t("sites.evolution.subtitle_orange") : t("sites.evolution.subtitle_green")

  return (
    <CardWrapper resource="sites" type="evolution" subtitleCallBack={subtitle_text} unclickable={true} custom_tooltip={warning_text} isRedWarning={warning_color === "red" || qualityWarning === "red"} isOrangeWarning={warning_color === 'orange' || qualityWarning === "orange"} >
      <QueryInfiniteField query_name="getSiteTimeseries" params={{ time: { between: [startDate, endDate] } }}>
        <EvolutionReceiver />
        <ChartGauge
          consumptionVariation={consumption}
          isLoading={componentIsLoading}
        />
      </QueryInfiniteField>
    </CardWrapper>
  )
};

export default CardEvolution;

