import { TextField, AutocompleteInput, TextInput } from 'react-admin';
import React from 'react';
import { useTranslate, FunctionField } from "react-admin";
import { Chip } from '@mui/material';

// Components
import SiteIcon from "../../assets/SiteIcon";
import CustomList from '../aegeon/CustomList';

const SitesList = () => {
  const t = useTranslate();
  let defaultSortFields = [{ field: "airtable.current_synoptic_quality_reporting", order: "ASC" }, { field: "airtable.code", order: "ASC" }]

  const qualityChoices = [{ id: "A", name: "A" }, { id: "B", name: "B" }, { id: "C", name: "C" }, { id: "D", name: "D" }]

  // TODO : fix those filters
  const filters = [
    <TextInput source="airtable.code" label={t("sites.list.code")} />,
    <TextInput source="name" label={t("sites.list.name")} />,
    <AutocompleteInput label={t("sites.general_information.synoptic_quality")} source="airtable.current_synoptic_quality_reporting.eq" choices={qualityChoices} noOptionsText={t("resources.global.no_options")} />,
    // <TextInput source="airtable.fluids_typology" label={t("sites.general_information.fluid")} />,
  ];

  const defaultSortData = (data, sortedFieldsList) => {
    data.sort((a, b) => {
      if (sortedFieldsList.filter((elem) => elem.field === "airtable.current_synoptic_quality_reporting").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "airtable.current_synoptic_quality_reporting")[0].order
        const a_current_synoptic_quality_reporting = a.airtable?.current_synoptic_quality_reporting ? a.airtable?.current_synoptic_quality_reporting : "Z";
        const b_current_synoptic_quality_reporting = b.airtable?.current_synoptic_quality_reporting ? b.airtable?.current_synoptic_quality_reporting : "Z";
        if (a_current_synoptic_quality_reporting < b_current_synoptic_quality_reporting) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_current_synoptic_quality_reporting > b_current_synoptic_quality_reporting) {
          return order === "ASC" ? 1 : -1;
        }
      }
      if (sortedFieldsList.filter((elem) => elem.field === "airtable.code").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "airtable.code")[0].order
        const a_code = a.airtable?.code ? a.airtable?.code : "";
        const b_code = b.airtable?.code ? b.airtable?.code : "";
        if (a_code < b_code) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_code > b_code) {
          return order === "ASC" ? 1 : -1;
        }
      }
      if (sortedFieldsList.filter((elem) => elem.field === "name").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "name")[0].order
        const a_name = a.name ? a.name : -1;
        const b_name = b.name ? b.name : -1;
        if (a_name < b_name) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_name > b_name) {
          return order === "ASC" ? 1 : -1;
        }
      }
      if (sortedFieldsList.filter((elem) => elem.field === "airtable.fluids_typology").length > 0) {
        let order = sortedFieldsList.filter((elem) => elem.field === "airtable.fluids_typology")[0].order
        const a_fluids_typology = a.airtable?.fluids_typology ? a.airtable?.fluids_typology : "";
        const b_fluids_typology = b.airtable?.fluids_typology ? b.airtable?.fluids_typology : "";
        if (a_fluids_typology < b_fluids_typology) {
          return order === "ASC" ? -1 : 1;
        }
        if (a_fluids_typology > b_fluids_typology) {
          return order === "ASC" ? 1 : -1;
        }
      }
      return 0
    })
    return data
  }


  const fields = [
    <TextField source="airtable.code" label={t("sites.list.code")} key="airtable.code" />,
    <FunctionField source="name" label={t("sites.list.name")} render={(record) => { return record.name === "undefined" ? "" : record.name }} key="name" />,
    <TextField source="airtable.current_synoptic_quality_reporting" label={t("sites.general_information.synoptic_quality")} key="airtable.current_synoptic_quality_reporting" />,
    <FunctionField source="airtable.fluids_typology" label={t("sites.general_information.fluid")} render={(record) => {
      return record?.airtable?.fluids_typology ? record?.airtable?.fluids_typology.sort(
        (a, b) => parseInt(t("devices.general_information.fluids.order." + a),10) < parseInt(t("devices.general_information.fluids.order." + b),10) ? -1 : 1
      ).filter(element => element).map((element, index) =>
        <Chip key={index} size="small" label={t("sites.general_information.fluids." + element)} />)
        : ""
    }} key="airtable.fluids_typology" />,
  ]

  return (
    <CustomList defaultSortFields={defaultSortFields} filters={filters} defaultSortData={defaultSortData} Icon={SiteIcon} resource="sites" fields={fields} sortingByDefault={true} />
  );
};

export default SitesList;