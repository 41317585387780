// /** @jsx React.createElement */
// /** @jsxFrag React.Fragment */
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import { usePagination, useSearchBox } from "react-instantsearch-hooks";
import React, { createElement, Fragment, useEffect, useRef, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { render } from "react-dom";
import "@algolia/autocomplete-theme-classic";
import "../../styles/algolia.scss";
import Item from "./Item";
import { Auth } from "aws-amplify";
import { useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { useGetList } from "react-admin";
import { filter } from "lodash";

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

export default function Autocomplete(className, ...props) {
  const containerRef = useRef(null);
  const t = useTranslate();

  const { data: devices } = useGetList(
    'devices'
  );

  const { data: sites } = useGetList(
    'sites'
  );

  const { query, refine: setQuery } = useSearchBox();
  const [instantSearchIndexName, setInstantSearchIndexName] = useState("");
  const { refine: setPage } = usePagination();

  const [instantSearchUiState, setInstantSearchUiState] = useState({ query });

  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 5,
    transformSource({ source, onRemove }) {
      return {
        ...source,
        addItem({ source, item }) {
          if (item.__autocomplete_indexName === instantSearchIndexName) {
            source.store.addItem(item);
          }
        },
        templates: {
          ...source.templates,
          item(params) {
            const { item, html } = params;
            return html`<a
              class="aa-Item aa-ItemLink aa-RecentSearch"
              
            >
              ${source.templates.item(params).props.children}
            </a>`;
          },
        },
      };
    },
    });

  // Get the user group
  useEffect(() => { 
    const fetchUserGroup = async () => {
      let index = await Auth.currentAuthenticatedUser().then(
        (data) => data.signInUserSession.accessToken.payload["cognito:groups"]
      );
      index = index.filter((i) => !i.startsWith("role_"));
      setInstantSearchIndexName(index[0]);
    };
    fetchUserGroup().catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setQuery(instantSearchUiState.query);
    setPage(0);
  }, [instantSearchUiState]);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }
    if (instantSearchIndexName === "") {
      return undefined;
    }

    const availableResources = filter(devices, (device) => device.id !== null).concat(filter(sites, (site) => site.id !== null));

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render },
      placeholder: t("search.placeholder"),
      plugins: [recentSearchesPlugin],
      openOnFocus: true,
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          setInstantSearchUiState({
            query: state.query,
          });
        }
      },
      getSources({ query }) {
        return [
          {
            sourceId: "asserts",
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: instantSearchIndexName,
                    query,
                    params: {
                      hitsPerPage: 10,
                      attributesToSnippet: ["name:10", "resource:35"],
                      snippetEllipsisText: "…",
                      filters: `${availableResources.map((resource) => `objectID:${resource.id}`).join(" OR ")}`,
                    },
                  },
                ],
              });
            },
            getItemUrl({ item }) {
              return "#/" + item.resource + "s/" + item.id + "/show";
            },
            templates: {
              item({ item, components }) {
                return <Item hit={item} components={components} t={
                  function (key) {
                    return t(key);
                  }
                }/>;
              },
              noResults() {
                return (
                  <div className="aa-no-results">
                    {t("search.noResults")}
                  </div>
                );
              }
            },
          },
        ]
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [instantSearchIndexName, sites, devices]);

  return (
    <div
      ref={containerRef}
    />
  )
}