import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { default as React, useState } from 'react';
import { useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ExportButton from '../../Buttons/ExportButton';
import CustomTooltip from '../../aegeon/CustomTooltip';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';
import LineCardDetails from './LineCardDetails';

const LineDetails = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const title = t("devices.power.title");
  const [data, setData] = useState(null);

  const setDataCallback = (childData) => {
    if (childData) {
      setData(childData)
    }
  }

  const handleExport = (name) => {
    var sheets_data = [];
    if (data) {
      sheets_data.push({ sheetName: "Courbe de charge", data: data });
      export_row_to_excel(sheets_data, `${t('devices.exports.line')}${name}.xlsx`);
    };
  };

  return (
    <DetailsShow title={title} resource="devices" tooltip={<ExportButton onClick={handleExport} disabled={!data} />}>
      <Card className={`${globalClasses.DetailCard}`}>
        <div className={globalClasses.DetailCardTableTitle}>
          <Typography variant="h8">{t('devices.power.detailledTitle')}</Typography>
          {<CustomTooltip title={t('devices.power.tooltip_text')} icon={<InfoTooltipIcon />} />}
        </div>
        <div className={globalClasses.DetailCardTableSubtitle}>
          {t('devices.power.subtitle')}
        </div>
        <LineCardDetails setDataCallback={setDataCallback} />
      </Card>
    </DetailsShow>
  )
};

export default LineDetails;

