import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { EditorState, convertFromRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';
import React, { useState } from 'react';
import { useNotify, useRecordContext, useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import CustomTooltip from '../CustomTooltip';
import useMutationProvider from '../mutation/useMutationProvider';
import useSubscriptionProvider from '../subscription/useSubscriptionProvider';
import AnalysisCardTextDetails from './AnalysisCardTextDetails';

const AnalysisCardDetails = (props) => {
  const t = useTranslate();
  const [isStreaming, setIsStreaming] = useState(false);
  const [isGenerationFinished, setIsFinished] = useState(false);
  const [isQuotaReached, setIsQuotaReached] = useState(false);
  const [systemAnalysisState, setSystemAnalysisState] = useState(EditorState.createEmpty());
  const [userAnalysisState, setUserAnalysisState] = useState(EditorState.createEmpty());
  const [analysisState, setAnalysisState] = useState(EditorState.createEmpty());
  const globalClasses = globalUseStyles();
  const notify = useNotify();
  const {
    analysisClass,
    analysisType,
    setDataCallback
  } = props;

  const onSuccessCallback = (data, variables, context) => {
    if (data.data?.status === "QUOTA_REACHED") {
      setIsQuotaReached(true);
      notify(t("resources.analyse.notify"), { type: "warning" });
    }
    else {
      var rawObject = markdownToDraft(data.data.system_prompt);
      const systemAnalysisContentState = convertFromRaw(rawObject);
      setSystemAnalysisState(EditorState.createWithContent(systemAnalysisContentState));

      rawObject = markdownToDraft(data.data.user_prompt);
      const userAnalysisContentState = convertFromRaw(rawObject);
      setUserAnalysisState(EditorState.createWithContent(userAnalysisContentState));
      if (isStreaming === false) {
        setIsStreaming(true);
      }
    }

  };

  const onStreamedData = (provider, value) => {
    var rawObject = markdownToDraft(value.data.streamedAnalysis.assistent_prompt);
    const analysisContentState = convertFromRaw(rawObject);
    setAnalysisState(EditorState.createWithContent(analysisContentState));

    if (value.data.streamedAnalysis?.status === "FINISHED") {
      setIsStreaming(false);
      setIsFinished(true);
    }
  }

  const record = useRecordContext(props);
  const mutation = useMutationProvider("updateAnalysis", { analysisClass, analysisType }, undefined, onSuccessCallback);
  const subscribe = useSubscriptionProvider({ subscriptionName: "streamedAnalysis", queryParams: { id: record.id, analysisClass: analysisClass, analysisType: analysisType } }, onStreamedData);

  const { data, isLoading, isSuccess, isIdle } = mutation;

  const handleOnClick = () => {
    mutation.mutate({ mutation_name: "updateAnalysis", id: record.id, input: { analysisClass, analysisType } })
  };

  const isButtonLoading = isLoading || (isSuccess && isStreaming);

  return (
    <div>
      <div style={{ paddingTop: "30px", paddingBottom: "30px" }} >
        <LoadingButton padding={2}
          onClick={handleOnClick}
          endIcon={(isQuotaReached && !isLoading && !isStreaming) ? <CustomTooltip title={t("resources.analyse.disabled_tooltip")} icon={<InfoTooltipIcon color="white" />} /> : <></>}
          loading={isButtonLoading}
          loadingPosition="end"
          variant="contained"
          color="primary"
          className={globalClasses.customButton}
          disabled={isQuotaReached || isButtonLoading}
        >
          <Typography variant="h3" color="white !important">
            {isButtonLoading ? t("resources.analyse.loading_button_text") : t("resources.analyse.button_text")}
          </Typography>
        </LoadingButton>
      </div>
      {
        isLoading === true ?
          <AnalysisCardTextDetails
            title={t("resources.analyse.is_loading_title")}
            analysisType={analysisType}
            analysisClass={analysisClass}
            loadingAnimation={true}
            currentDate={true}
            isLoading={true}
            setDataCallback={setDataCallback}
          />
          :
          isStreaming === true ?
            <AnalysisCardTextDetails
              title={(t("resources.analyse.streaming_title"))}
              analysisType={analysisType}
              analysisClass={analysisClass}
              customAnalysisState={analysisState}
              loadingAnimation={true}
              currentDate={true}
              setDataCallback={setDataCallback}
            />
            :
            isGenerationFinished === true ?
              <AnalysisCardTextDetails
                title={t("resources.analyse.streaming_finished_title")}
                analysisType={analysisType}
                analysisClass={analysisClass}
                customAnalysisState={analysisState}
                currentDate={true}
                setDataCallback={setDataCallback}
              />
              :
              isIdle === true ?
                <AnalysisCardTextDetails
                  title={t("resources.analyse.last_analyse_title")}
                  analysisType={analysisType}
                  analysisClass={analysisClass}
                  isIdle={true}
                  setDataCallback={setDataCallback}
                />
                :
                <div></div>
      }
    </div >
  )
};

export default AnalysisCardDetails;

